/**
 * Available game attributes
 */
export enum GameAttribute {
  NEW = 32,
  POPULAR = 33,
  TOURNAMENT = 35,
  JACKPOT = 29,
  FS = 103,
  FLASH = 31,
  ADAPTIVE_FLASH = 24,
  LIVE = 27,
  NODEMO = 26,
  DAILY_DROPS = 68,
  BITCOIN = 70,
  LIVE_ROULETTE = 150,
  LIVE_BLACKJACK = 151,
  LIVE_BACCARAT = 152,
  LIVE_SHOWS = 153,
  PRE_RELEASE = 147,
  MYSTERY_DROP = 155,
  BGAMING_DROPS = 158
}

/**
 * Attribute for filte contract
 */
export interface GameAttributeForFilter {
  name: string;
  alias: string;
  id: string | number;
}

/**
 * List of options for attribute filter
 */
export const attributesForFilter: Array<GameAttributeForFilter> = [
  {name: 't.new', alias: 'new', id: GameAttribute.NEW},
  {name: 't.popular', alias: 'popular', id: GameAttribute.POPULAR}
];
