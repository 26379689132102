import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {catchError, filter, map, share, shareReplay, switchMap, tap} from 'rxjs/operators';
import {DEFAULT_TRANSLATES, ILootboxesTranslatesCMS} from './lootbox-translates';
import {SsApiService} from '../api/ss-api.service';
import {TimeService} from '../time.service';
import {StaticContentService} from '../static-content.service';
import {BONUS_TYPES, BonusStage} from '../user/data/user-bonuses.data';

const lootboxImages: string[] = [
  '/assets/img/lootbox/coins.png',
  '/assets/img/lootbox/diamonds.png',
  '/assets/img/lootbox/gold.png',
  '/assets/img/lootbox/hat.png',
  '/assets/img/lootbox/horseshoe.png',
  '/assets/img/lootbox/coins.png',
  '/assets/img/lootbox/diamonds.png',
  '/assets/img/lootbox/gold.png',
  '/assets/img/lootbox/hat.png',
  '/assets/img/lootbox/horseshoe.png'
];

const LOOTBOXES_TRANSLATES_SLUG = 'lootboxes-translates';

@Injectable({
  providedIn: 'root'
})
export class LootboxService {

  /**
   * Player has issued lootbox
   */
  private _playerHasIssuedLootbox: boolean = null;

  /**
   * Player has issued lootbox
   */
  private _playerHasPromoLootbox: boolean = null;

  /**
   * Lootbox list
   */
  private _lootboxList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Lootbox issued list
   */
  private _lootIssuedboxList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Lootbox issued list
   */
  private _lootboxPromoList$: ReplaySubject<any> = new ReplaySubject<any>(1);


  /**
   * Current chosen lootbox prize
   */
  public currentLootboxPrize: any;


  /**
   * Set true if lootboxes translates loaded from CMS
   * @private
   */
  private _lootboxesTranslatesLoaded = false;

  private _isValidUntilLootboxTime: any;

  /**
   * Lootboxes translates Replay Subject
   * @private
   */
  public lootboxesTranslates$ = this._static.item({slug: LOOTBOXES_TRANSLATES_SLUG}).pipe(
    filter(data => Boolean(data) && !this._lootboxesTranslatesLoaded),
    tap(data => this._lootboxesTranslatesData = data[0]),
    catchError(() => {
      this._lootboxesTranslatesLoaded = true;
      this._lootboxesTranslatesData = {
        FieldModifyTranslations: null,
        Translates: {}
      };
      return of({});
    }),
    shareReplay(1)
  );

  /**
   * Save lootboxes translates data for use in future
   * @private
   */
  private _lootboxesTranslatesData: ILootboxesTranslatesCMS;

  /**
   * True if is today activated promo lootbox
   * @private
   */
  private _isActivatedTodayPromoLootbox: boolean;

  constructor(
    private _ssApi: SsApiService,
    private _time: TimeService,
    private _static: StaticContentService,
  ) {
  }

  public get lootboxList$(): ReplaySubject<any> {
    return this._lootboxList$;
  }

  public get lootIssuedboxList$(): ReplaySubject<any> {
    return this._lootIssuedboxList$;
  }

  public get lootboxPromoList$(): ReplaySubject<any> {
    return this._lootboxPromoList$;
  }
  get isActivatedTodayPromoLootbox(): boolean {
    return this._isActivatedTodayPromoLootbox;
  }

  get isValidUntilLootboxTime(): any {
    return this._isValidUntilLootboxTime;
  }

  /**
   * Load user loot box
   */
  public loadUserLoobox() {
    return this.lootboxesTranslates$.pipe(
      switchMap(() => this._getAndResolveLootboxes()),
      map(list => list?.map(e => {
        if (e) {
          e.lootboxTitle = e?.title;
          e.type = BONUS_TYPES.LOOTBOX;
          e.cancelable = e?.stage !== BonusStage.EXPIRED;
          e.activatable = true;
        }
        return e;
      })),
    );
  }

  /**
   * Get and resolve lootboxes
   * @private
   */
  private _getAndResolveLootboxes(): Observable<any> {
    return this._ssApi.playerLootbox().pipe(
      tap((list: any[]) => {
        this._resolveLootboxByOptions(
          list, 'stage', 'issued', this._lootIssuedboxList$, '_playerHasIssuedLootbox'
        );
        this._resolveLootboxByOptions(
          list, 'title', 'landing', this._lootboxPromoList$, '_playerHasPromoLootbox', true
        );

        this._isActivatedTodayPromoLootbox = list.some(l => l.stage === 'activated' && l.title.toLowerCase().includes('landing') && this._time.isTodayDate(new Date(l.valid_until)));
        this._isValidUntilLootboxTime = list.find(l => l.stage === 'activated' && l.title.includes('landing') && this._time.isTodayDate(new Date(l.valid_until)))?.valid_until;

        this._lootboxList$.next(list.map(l => ({
          ...l,
          lootboxTitle: this.mapBonusTitle(l?.title)?.trim().toLowerCase(),
          created_at: l?.created_at ? new Date(l?.created_at) : null
        })));
      })
    );
  }

  /**
   * Resolve lootbox for different options
   *
   * @param list
   * @param field
   * @param fieldValue
   * @param list$
   * @param flag
   * @param isLanding
   * @private
   */
  private _resolveLootboxByOptions(
    list: any[], field: string, fieldValue: string, list$: ReplaySubject<any>, flag: string, isLanding?: boolean
  ) {
    if (list.length) {
      const lootboxList = list
        .filter(l => isLanding ? l.stage === 'issued' && l[field].includes(fieldValue) : l[field].includes(fieldValue))
        .map(l => ({...l, ...this.resolveLootboxTranslates(l), strategy: 'lootbox_item',  _lootbox_image: this._resolveImageLootbox(l), type: BONUS_TYPES.LOOTBOX}));
      this._resolveValidUntil(lootboxList);
      list$.next(lootboxList);
      this[flag] = Boolean(lootboxList.length);
      return lootboxList;
    } else {
      list$.next([]);
      this[flag] = false;
      return [];
    }
  }

  private _resolveImageLootbox(lootbox: any) {
    const arr = String(lootbox.id).split('');
    const lastIdDigit = Number(arr[arr.length - 1]);
    return lootboxImages[lastIdDigit];
  }

  /**
   * Check time to finish
   * @param issuedLootbox
   */
  private _resolveValidUntil(issuedLootbox: any[]) {
    if (issuedLootbox.length) {
      issuedLootbox.forEach(lootbox => {
        const endAt = this._time.toLocalDate(lootbox.valid_until);
        lootbox.endAt = this._time.timeDiff(endAt);
      });
    }
  }

  /**
   * Activate lootbox by id
   * @param id
   */
  public activateLootbox(id) {
    return this._ssApi.playerLootboxActivate(id);
  }

  /**
   * Cancel lootbox by id
   * @param id
   */
  public cancelLootbox(id) {
    return this._ssApi.playerLootboxDelete(id).pipe(
      switchMap(() => this.loadUserLoobox())
    );
  }

  /**
   * Resolve lootbox translates for use in template
   * Find lootbox by field (FieldModifyTranslations) from CMS and add translates
   * @param lootbox
   * @private
   */
  public resolveLootboxTranslates(lootbox: any) {
    const translatesArr = Object.entries(this._lootboxesTranslatesData && this._lootboxesTranslatesData.Translates || {});

    if (translatesArr.length && this._lootboxesTranslatesData.FieldModifyTranslations) {
      const field = this._lootboxesTranslatesData.FieldModifyTranslations.trim();

      const translates = translatesArr.find(tr => {
        if (tr[0].includes(',')) {
          return tr[0]
            .trim()
            .toLocaleLowerCase()
            .split(',')
            .map(t => t.trim())
            .some(t => this.mapBonusTitle(String(lootbox[field])).trim().toLocaleLowerCase() === t.trim().toLocaleLowerCase());
        }

        return this.mapBonusTitle(String(lootbox[field])).trim().toLocaleLowerCase() === tr[0].trim().toLocaleLowerCase();
      });

      if (translates) {
        lootbox = {...lootbox, ...translates[1]};
      } else {
        lootbox = {...lootbox, ...DEFAULT_TRANSLATES};
      }
    } else {
      lootbox = {...lootbox, ...DEFAULT_TRANSLATES};
    }

    return lootbox;
  }

  /**
   * Prepare bonus title for using in frontend
   *
   * @private
   */
  public mapBonusTitle(title: string): string {
    return (title || '')
      .replace(/\[(.*?)]/g, '')
      .replace(/\[(.*?)}/g, '')
      .replace(/\{(.*?)]/g, '')
      .replace(/\{(.*?)}/g, '');
  }

  /**
   * Get wheel animation static item
   */
  public getWheelAnimationData(): Observable<any> {
    return this._static.item({slug: 'wf-lootbox-wheel-animation'}).pipe(
      filter(data => data?.length),
      share()
    );
  }

  /**
   * Get wheel index by CMS data
   * @param data
   * @private
   */
  public getWheelIndex(data: any[], lootbox: any) {
    const cmsData = data?.[0];
    return +(Object.entries(cmsData?.Bonuses).find(([key, value]) => {
      return (value as any)?.id?.split(',')?.map(s => s?.trim())?.some(s => s === lootbox?.group_key)
    })?.[0]);
  }
}
