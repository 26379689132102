import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { showHide } from '../../modal-animations';
import { Modal } from '../../modal';

@Component({
    selector: 'app-modal-template-no-close',
    templateUrl: './modal-template-no-close.component.html',
    styleUrls: ['./modal-template-no-close.component.scss'],
    animations: [showHide],
    standalone: true
})
export class ModalTemplateNoCloseComponent extends Modal implements OnInit {

  @ViewChild('content', { static: true, read: ViewContainerRef }) private contentRef: ViewContainerRef;

  constructor() {
    super();
  }

  ngOnInit() {}

}
