@if (notify) {
  <div class="item">
    <div class="item__left">
      <div class="item__img">
        <img [src]="notify?.icon" alt="">
      </div>
      <img src="/assets/img/notification/trash.svg" (click)="notification.removeNotify(notify)">
    </div>
    <div class="item__right">
      <div [routerLink]="notify?.link" class="item__title" (click)="notification.close(); notification.removeNotify(notify)">{{ notify?.templateTitle | translate }}</div>
      <div class="item__description" [routerLink]="notify?.link" (click)="notification.close(); notification.removeNotify(notify)" [innerHTML]="notify?.description | safe: 'html'"></div>
    </div>
  </div>
}
