<form class="form" [formGroup]="form" (ngSubmit)="submit()" [ngClass]="{'deposit-complete': isDepositComplete}">
  <div class="form__title">{{ (isDepositComplete ? 't.deposit-complete' : 't.update-profile') | translate }}</div>

  <div class="input-container">
    @if (field('email')) {
      <app-form-input class="input-item" [errors]="errors.email">
        <input class="input input--simple" type="email" name="email" [placeholder]="'t.enter-email-continue' | translate" formControlName="email">
      </app-form-input>
    }

    @if (field('profile_attributes.currency')) {
      <app-form-input class="input-item" [errors]="errors.currency" formGroupName="profile_attributes">
        <app-select class="select" formControlName="currency">
          <app-select-header class="select__header">{{'t.currency' | translate}}</app-select-header>
          <app-select-dropdown class="select__dropdown">
            @for (currency of commonData.currencyList; track currency) {
              <app-select-option class="select__option" [value]="currency.code">{{ currency.code }}</app-select-option>
            }
          </app-select-dropdown>
        </app-select>
      </app-form-input>
    }

    @if (field('profile_attributes.terms_acceptance')) {
      <app-form-input class="input-item" [errors]="errors.terms_acceptance" formGroupName="profile_attributes">
        <label class="checkbox">
          <input type="checkbox" formControlName="terms_acceptance">
          <span class="checkbox__view"></span>
          @if (!isDepositComplete) {
            <span class="checkbox__label">{{ 't.years-old' | translate }}
              <a [routerLink]="['/', 'terms']" target="_blank" class="text-color-secondary">{{ 't.terms-and-cond' | translate }}</a> {{ 't.and' | translate }} <a [routerLink]="['/', 'policy']" target="_blank" class="text-color-secondary">{{ 't.privacy-policy' | translate }}&nbsp;*</a>
            </span>
          } @else {
            <span class="checkbox__label">{{ 't.years-old' | translate }}
              <span class="checkbox__popup-link" (click)="openInfoModal(terms)">{{ 't.terms-and-cond' | translate }}</span>
              {{ 't.and' | translate }} <span  class="checkbox__popup-link" (click)="openInfoModal(privacy)">{{ 't.privacy-policy' | translate }}&nbsp;*</span>
            </span>
          }
        </label>
      </app-form-input>
    }
  </div>

  <div class="submit-container">
    @if (!loading) {
      <button class="btn btn--filled-primary" type="submit">{{'t.save' | translate}}</button>
    }
    @if (loading) {
      <app-preloader></app-preloader>
    }
  </div>
</form>
