/**
 * Available bonus types
 */
export enum BonusType {
  MONEY = 'money',
  FREE_SPINS = 'free_spins',
  LOOTBOX = 'lootbox',
}

export enum BonusTypes {
  WELCOME = 'welcome',
  RELOAD = 'reload',
  LOYALTY = 'loyalty',
  DAILY = 'daily',
  RELOAD_PHONE_VERIFICATION = 'reload-phone-verification',
  BLOG = 'blog'
}
