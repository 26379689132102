import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-unificator/services';
import {StaticContentService} from './static-content.service';
import {filter, tap} from 'rxjs/operators';

export const NATIVE_APP_COOKIE = 'isNativeApp';

@Injectable({
  providedIn: 'root'
})
export class InstallAppService {

  /**
   * Variables for show/hide install app block logic (register block, notification message, banner after deposit)
   * @private
   */
  private _showInstallAppRegisterBlock: boolean;
  private _showInstallAppNotificationMessage: boolean;
  private _showInstallAppAfterDepositBanner: boolean;
  private _canShowAfterDepositBanner: boolean;

  /**
   * Cookie name variables
   */
  private _registerCookieName: string = '--register-install-app';
  private _notificationCookieName: string = '--notification-install-app';
  private _depositCookieName: string = '--deposit-install-app';

  private _resolvedVariables: boolean;

  private _hideInstallAppByCMS: boolean;

  constructor(
    private _cookie: CookieService,
    private _static: StaticContentService
  ) {
  }

  /**
   * Access from outside
   */
  get showInstallAppRegisterBlock() { return this._showInstallAppRegisterBlock; }
  get showInstallAppNotificationMessage() { return this._showInstallAppNotificationMessage; }
  get showInstallAppAfterDepositBanner() { return this._showInstallAppAfterDepositBanner; }
  get canShowAfterDepositBanner() { return this._canShowAfterDepositBanner; }
  set showInstallAppAfterDepositBanner(value) { this._showInstallAppAfterDepositBanner = value; }

  get isNativeApp(): boolean { return this._cookie.check(NATIVE_APP_COOKIE); }
  get hideInstallAppByCMS(): boolean { return this._hideInstallAppByCMS; }

  /**
   * Resolve show variables (check cookie)
   * @private
   */
  public resolveShowVariables() {
    if (!this._resolvedVariables) {
      this._showInstallAppRegisterBlock = !Boolean(this._cookie.get(this._registerCookieName));
      this._showInstallAppNotificationMessage = !Boolean(this._cookie.get(this._notificationCookieName));
      this._canShowAfterDepositBanner = !Boolean(this._cookie.get(this._depositCookieName));
      this._resolvedVariables = true;
    }
  }

  public closeRegisterBlock() {
    this._showInstallAppRegisterBlock = false;
    this._cookie.set(this._registerCookieName, '1');
  }

  public closeNotificationBlock() {
    this._showInstallAppNotificationMessage = false;
    this._cookie.set(this._notificationCookieName, '1');
  }

  public closeDepositBlock() {
    this._showInstallAppAfterDepositBanner = false;
    this._cookie.set(this._depositCookieName, '1');
  }

  public checkIfShowInstallAppByCMS() {
    return this._static.item({ slug: 'show-install-app' }).pipe(
      filter(list => !!list),
      tap((list) => this._hideInstallAppByCMS =  !list.length)
    );
  }
}
