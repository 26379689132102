import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import {filter, first, switchMap, tap} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import { PlatformService } from './platform.service';
import {StaticContentService} from './static-content.service';
import {UserService} from './user/user.service';
import {LocalstorageService} from './localstorage.service';
import {LowPlayerBalanceService} from './low-player-balance.service';
import {ScriptLoaderService} from './script-loader.service';

interface AdmilRequest {
  sales?: string;
  amount?: string;
  orderid?: string;
}

enum AdmilActions {
  REGISTER = 'Wildfortune_io_Registeration',
  LOGIN = 'Wildfortune_io_Login',
  VISIT = 'Wildfortune_io_All_Pages',
  DEPOSIT = 'Wildfortune_io_Recuring_Deposit',
  FIRST_DEPOSIT = 'Wildfortune_io_FTD'
}

declare  var adf
@Injectable({
    providedIn: 'root'
})
export class AdmilService {

  private _admilHost = 'track.adform.net'

  /**
   * True if cms static request loaded
   * @private
   */
  private staticLoaded: boolean;

  /**
   * Emit true if ubidex available
   * @private
   */
  private _availableAdmil$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _platform: PlatformService,
    private _static: StaticContentService,
    private _user: UserService,
    private _storage: LocalstorageService,
    private _lowBalance: LowPlayerBalanceService,
    private _scriptLoader: ScriptLoaderService
  ) {
    this._checkIsAdmilAvailableByCMS();
  }

  /**
   * Check is ubidex available by cms
   * @private
   */
  private _checkIsAdmilAvailableByCMS() {
    if (!this.staticLoaded) {
      this._static.item({slug: 'admil'}).pipe(
        filter(data => Boolean(data?.length)),
        switchMap(() => this._scriptLoader.load('https://s2.adform.net/banners/scripts/st/trackpoint-async.js', undefined, {async: true})),
        tap(() => this._availableAdmil$.next(true))
      ).subscribe(() => this.staticLoaded = true);
    }
  }

  /**
   * Sends a register event to Admil tracking when a user registers.
   * sets the event to 'regfinished', and sends the tracking pixel.
   */
  public onRegister(user) {
    this._availableAdmil$.pipe(
      filter(available => !!available),
      first(),
      tap(() => {
        const data = {
          orderid: user?.id,
        };
        this._sendPixel(data, AdmilActions.REGISTER);
      })
    ).subscribe();
  }

  /**
   * Sends a login event to Admil tracking when a user logs in.
   * sets the event to 'login', and sends the tracking pixel.
   */
  public onLogin(user) {
    this._availableAdmil$.pipe(
      filter(available => !!available),
      first(),
      tap(() => {
        const data = {
          orderid: user?.id,
        };
        this._sendPixel(data, AdmilActions.LOGIN);
      })
    ).subscribe();
  }

  /**
   * Sends a visit event to Admil tracking when a user visits.
   * sets the event to 'visit', and sends the tracking pixel.
   */
  public onVisit(user = null) {
    this._availableAdmil$.pipe(
      filter(available => this._platform.isBrowser && !!available),
      tap(() => {
        if (user?.id) {
          if (this._window.sessionStorage.getItem('visited')) {
            return;
          }
          this._sendPixel({}, AdmilActions.VISIT);
          this._window.sessionStorage.setItem('visited', '1');
        } else {
          if (this._window.sessionStorage.getItem('visited-non-auth')) {
            return;
          }

          this._sendPixel({}, AdmilActions.VISIT);
          this._window.sessionStorage.setItem('visited-non-auth', '1');
        }
      })
    ).subscribe();
  }

  /**
   * Sends a deposit event to Admil tracking when a user makes a deposit.
   * sets the event to 'deposit', and sends the tracking pixel.
   */
  public onDeposit(user?, depData?: any) {
    this._availableAdmil$.pipe(
      filter(available => !!available),
      filter(() => !!depData.amount && !!depData.currency),
      first(),
      tap(() => {
        const data = {
          currency: depData?.currency,
          orderid: user?.id,
          sales: depData?.amount,
        };
       this._sendPixel(data, AdmilActions.DEPOSIT);
      })
    ).subscribe();
  }

  /**
   * Sends a first deposit event to Admil tracking when a user makes a deposit.
   * sets the event to 'deposit', and sends the tracking pixel.
   */
  public onFirstDeposit(user, depData: any) {
    this._availableAdmil$.pipe(
      filter(available => !!available),
      filter(() => !!depData.amount && !!depData.currency),
      first(),
      tap(() => {
        const data = {
          currency: depData?.currency,
          orderid: user?.id,
          sales: depData?.amount,
        };
        this._sendPixel(data, AdmilActions.FIRST_DEPOSIT);
      })
    ).subscribe();
  }

  private _sendPixel(params: AdmilRequest, pagename: AdmilActions) {
    if (this._platform.isBrowser && adf) {
      adf.Params.HttpHost = this._admilHost;
      adf.ClickTrack(this, 3307088, encodeURIComponent(pagename),
        {
          ...params,
        }
      )
    }
  }

  public sendDepositInfoAdmil() {
    this._lowBalance.getLastDeposit$().pipe(
      first(),
    ).subscribe((data) => {

      const depositInfo = {
        amount: data,
        currency: this._user.currentCurrency.currency,
      }

      if (this._user.depositCount > 1) {
        this.onDeposit(this._user.info, depositInfo || JSON.parse(this._storage?.get('LAST_DEP_DATA') || '{}'));
      } else {
        this.onFirstDeposit(this._user.info, depositInfo || JSON.parse(this._storage?.get('LAST_DEP_DATA') || '{}'));
      }
    })
  }
}
