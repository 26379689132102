<div class="sign-up-container">
  <div class="form-container">
    <div #form class="form" [ngClass]="{'zoom-enable': breakpoints.zoomEnabled && isMobile(), 'countdown-timer': isCountdownTimerAvailable}">
      <!-- <img [@showHide] class="bonus-bg" src="/assets/img/sign-up/sign-up-bonus1.png" alt=""> -->
      <i class="close icon-close" (click)="onClose()"></i>
      <div class="form__title" id="sign-up-title">{{ 't.sign-up-title' | translate }}</div>
      <div class="form__description">{{ 't.sign-up-subtitle' | translate }}</div>
      <form [formGroup]="controller.form">
        <div>
          <div class="form__fields">
            <app-form-input  class="input-item" [label]="'t.email' | translate" [control]="controller.input('email')">
              <input class="input input--simple" id="email-input" type="email" name="email" formControlName="email" memoizeInvalid
                autofocus>
              </app-form-input>

              <app-form-input class="input-item" [label]="'t.passwd' | translate" [control]="controller.input('password')" [validationMessages]="[InputErrors.password_pattern]">
                <i class="icon icon-password password-toggle"></i>
                <input class="input input--simple password" type="password" name="password" PasswordShow
                  formControlName="password" id="password-input" autofocus memoizeInvalid>
                </app-form-input>

                <div class="form__fields_flex">
                  <app-form-input [label]="'t.nickname' | translate"  class="input-item form__fields_flex--bigger" [control]="controller.input('nickname')" [ngClass]="{'input-item--fr': userInfo?.isFR}">
                    <input class="input input--simple" id="nickname-input" type="text" name="nickname"
                      formControlName="nickname"
                      memoizeInvalid autofocus>
                    </app-form-input>

                    <div class="form__fields_flex--smaller" [hidden]="userInfo?.isFR">
                      <app-country-selector
                        class="input-item"
                        [countryName]="false"
                        [control]="controller.input('country')"
                        id="country"
                        [showArrow]="true"
                        >
                      </app-country-selector>
                    </div>
                  </div>
                </div>
              </div>
              @if (!env.isSe && !userInfo.isUserWithAffiliateLink) {
                <div class="bonus-code-container">
                  <div #bonusAccordion Accordion class="bonuscode-link" (click)="focusCode(codeInput)">
                    <span class="link link--secondary">{{'t.do-have-bonus-code' | translate}}</span>
                  </div>
                  <div class="bonus-code-form">
                    <app-form-input class="bonus-code-form__input"
                      [label]="'t.bonus-code' | translate"
                      [control]="controller.form.get('bonus_code')">
                      <input #codeInput class="input input--simple" formControlName="bonus_code" id="bonus-code-input" type="text" maxlength="30">
                    </app-form-input>
                    @if (!isSubmitedBonusCode) {
                      <button type="submit" [disabled]="!controller.form.get('bonus_code').value || controller.form.get('bonus_code').invalid"
                        (click)="onBonuscodeFormSubmit()"
                      class="bonus-code-form__action btn btn--primary">{{'t.send' | translate}}</button>
                    }
                    @if (isSubmitedBonusCode) {
                      <button type="button" class="bonus-code-form__action btn btn--primary"
                      (click)="deleteDepositeBonus()">{{'t.clear' | translate}}</button>
                    }
                  </div>
                </div>
              }
              <div class="checkbox-container">
                <app-form-input class="input-item" [control]="controller.input('terms_acceptance')">
                  <label class="checkbox">
                    <input type="checkbox" formControlName="terms_acceptance" id="terms-checkbox">
                    <span class="checkbox__view"></span>
                    <span class="checkbox__label">{{ 't.years-old' | translate }}
                      <a [routerLink]="['/', 'terms-and-conditions']" target="_blank" class="text-color-secondary">{{ 't.terms-and-cond' | translate }}</a> {{ 't.and' | translate }} <a [routerLink]="['/', 'privacy-policy']" target="_blank" class="text-color-secondary">{{ 't.privacy-policy' | translate }}&nbsp;*</a>
                    </span>
                  </label>
                </app-form-input>

                <app-form-input class="input-item">
                  <label class="checkbox">
                    <input type="checkbox" formControlName="receive_promos" id="receive-email-checkbox">
                    <span class="checkbox__view"></span>
                    <span class="checkbox__label" >{{ 't.receive-email' | translate }}</span>
                  </label>
                </app-form-input>
              </div>

              <div class="submit-container">
                @if (!controller.loading) {
                  <button class="btn btn--filled-primary big" (click)="controller.submitForm()" id="submit-register-button"
                  >{{ 't.sign-up' | translate }}</button>
                }
                @if (controller.loading) {
                  <app-preloader></app-preloader>
                }
              </div>
            </form>

            @if (welcomeOfferInfo$ | async; as welcomeOffer) {
              @if (welcomeOffer?.[0]?.Title) {
                <div class="welcome-offer">
                  <div [innerHTML]="welcomeOffer?.[0]?.Title | safe: 'html'"></div>
                  @if (welcomeOffer?.[0]?.Image; as imgSrc) {
                    <img [src]="imgSrc" [alt]="seo.metaTitle">
                  }
                </div>
              }
            }
          </div>

          <div class="countdown-banner" [hidden]="!isCountdownTimerAvailable" [style.height]="isMobile() && window.nativeWindow.innerHeight - form.offsetHeight - 50 > 125 ? window.nativeWindow.innerHeight - form.offsetHeight - 50 + 'px' : ''">
            <app-countdown-timer-banner (onBannerLoaded)="isCountdownTimerAvailable = $event"></app-countdown-timer-banner>
          </div>
        </div>

      </div>
