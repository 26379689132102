@if (timeLive) {
  <div class="tournament-timer">
    <i class="icon icon-time"></i>
    <span class="value">
      @if (!bloodMode) {
        <span class="value__days">{{timeLive.days}}<span class="value__label">{{timeLive.daysLabel | translate}}</span></span>
      } @else {
        <span class="value__days">
          <div class="blood__list">
            @for (item of timeLive.days; track item) {
              <span class="blood">
                {{item}}
                @for (item of timeLive?.daysConfig[item]?.dotSize; track item) {
                  <span class="drop"></span>
                }
              </span>
            }
          </div>
          <span class="value__label">{{timeLive.daysLabel | translate}}</span>
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style="width: 0;height: 0;">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -11" result="goo" />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
      }
      <span class="points"> : </span>
      <span class="value__hours">{{timeLive.hours}} <span class="value__label">{{timeLive.hoursLabel | translate}}</span> </span> <span class="points"> : </span>
      <span class="value__minutes">{{timeLive.minutes}} <span class="value__label">{{timeLive.minLabel | translate}}</span> </span>
    </span>
  </div>
}

