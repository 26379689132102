
import { ModalTemplateCleanComponent } from './modal-template-clean/modal-template-clean.component';
import { ModalTemplateDefaultComponent } from './modal-template-default/modal-template-default.component';
import { ModalTemplateLargeComponent } from './modal-template-large/modal-template-large.component';
import { ModalTemplateNoCloseComponent } from './modal-template-no-close/modal-template-no-close.component';

export const ModalTemplateListImports = [
  ModalTemplateDefaultComponent,
  ModalTemplateNoCloseComponent,
  ModalTemplateLargeComponent,
  ModalTemplateCleanComponent,
];

export const ModalTemplate = {
  DEFAULT: ModalTemplateDefaultComponent,
  NO_CLOSE: ModalTemplateNoCloseComponent,
  LARGE: ModalTemplateLargeComponent,
  CLEAR: ModalTemplateCleanComponent,
};

export type ModalTemplateType = keyof typeof ModalTemplate;
