import { Injectable, QueryList } from '@angular/core';
import { of, ReplaySubject } from 'rxjs';
import { ControlValueAccessor } from '@angular/forms';
import { SelectHeaderComponent } from './select-header/select-header.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { tap } from 'rxjs/operators';
import { delay, finalize } from 'rxjs/operators';
import { isNullOrUndefined } from '../../helpers/utils';

@Injectable()
export class SelectService implements ControlValueAccessor {

  /**
   * Value changes emitter
   */
  private _value$: ReplaySubject<any> = new ReplaySubject();

  /**
   * Current value
   */
  private _value: any;

  /**
   * Is dropdown menu opened
   */
  private _dropdownOpen: boolean;

  /**
   * Is select disabled
   */
  private _disabled: boolean;

  /**
   * Link to select header HTML element
   */
  private _header: SelectHeaderComponent;

  /**
   * Link to select dropdown HTML element
   */
  private _dropdown: SelectDropdownComponent;

  /**
   * Link to select option HTML element list
   */
  private _optionList: QueryList<SelectOptionComponent>;

  /**
   * onChange function container
   *
   * @param _
   * @private
   */
  private _onChange = (_: any) => { };

  /**
   * onTouched function container
   *
   * @param _
   * @private
   */
  private _onTouched = (_: any) => { };

  constructor() { }

  /**
   * Access to value emitter from outside
   */
  get value$(): ReplaySubject<any> {
    return this._value$;
  }

  /**
   * Access to current value from outside
   */
  get value(): any {
    return this._value;
  }

  /**
   * Access to dropdownOpen from outside
   */
  get dropdownOpen(): boolean {
    return this._dropdownOpen;
  }

  /**
   * Init required variables
   */
  init(header: SelectHeaderComponent, dropdown: SelectDropdownComponent) {
    this._header = header;
    this._dropdown = dropdown;
    this._optionList = dropdown.options;
  }

  /**
   * Open dropdown menu
   */
  open() {
    if (!this._dropdownOpen && !this._disabled) {
      this._dropdownOpen = true;
      this._dropdown.el.nativeElement.classList.add('open');
      this._header.el.nativeElement.classList.add('open');
    }
  }

  /**
   * Close dropdown menu
   */
  close() {
    if (this._dropdownOpen) {
      this._dropdownOpen = false;
      this._dropdown.el.nativeElement.classList.remove('open');
      this._header.el.nativeElement.classList.remove('open');
    } else {
      return;
    }

    /**
     * For remove stupid artefacts when selector closing
     */
    of(document).pipe(
      tap((doc: Document) => {
        doc.documentElement.style.transform = 'rotate(1.0001deg)';
        doc.documentElement.style.transform = 'rotate(0deg)';
      }),
      delay(0),
      finalize(() => document.documentElement.removeAttribute('style'))
    ).subscribe();
  }

  /**
   * Set disabled/enabled state for select
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this._disabled = disabled;

    requestAnimationFrame(() => {
      if (disabled) {
        this._header.el.nativeElement.setAttribute('disabled', 'true');
      } else {
        this._header.el.nativeElement.removeAttribute('disabled');
      }
    });
  }

  /**
   * Write value function
   *
   * @param value
   * @param change
   */
  writeValue(value: any, change = false): void {
    if (!isNullOrUndefined(value) && !this._disabled) {
      this._value = value;
      this._value$.next(value);

      if (change) {
        this._onChange(value);
      }

      setTimeout(() => {
        this._header.placeholder = false;
        this._header.value = this._getOptionContentByValue(value);
        this.close();
      });
    } else if (isNullOrUndefined(value)) {
      requestAnimationFrame(() => this._header.placeholder = true);
    }
  }

  /**
   * Register onChange function
   *
   * @param fn
   */
  registerOnChange(fn): void {
    this._onChange = fn;
  }

  /**
   * Register onTouched function
   *
   * @param fn
   */
  registerOnTouched(fn): void {
    this._onTouched = fn;
  }

  /**
   * Filter options by query
   *
   * @param query
   */
  filterOptions(query: string = ''): void {
    this._optionList.forEach((option: SelectOptionComponent) => {
      const el: HTMLElement = option.el.nativeElement;

      if (el.innerHTML.toLowerCase().includes(query.toLowerCase())) {
        el.style.display = 'block';
      } else {
        el.style.display = 'none';
      }
    });
  }

  /**
   * Returns html content of option with provided value
   *
   * @param value
   * @private
   */
  private _getOptionContentByValue(value: any): string {
    if (!this._optionList) {
      return '';
    }

    const options = this._optionList.toArray();
    const optionEl = options.filter(option => option.value === value)[0];

    if (!optionEl) {
      return '';
    }

    return optionEl.el.nativeElement.innerHTML;
  }
}
