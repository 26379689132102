<header class="header" [ngStyle]="{backgroundColor: changedColor}">
  <div class="container">
    <div class="content-container">
      <div class="left">
        <div class="menu">
          <img src="../../../../../assets/svg/burger.svg" alt="" (click)="sidenav.toggle()">
          <div class="notify">
            <app-notification-center-drop-down *appShellNoSSR></app-notification-center-drop-down>
          </div>
        </div>
      </div>
      <div class="center" [ngClass]="{'lottie': halloween.linkMenuEnabled}">
        <a [href]="seo.originUrl + '/' + lang.current" class="logo" title="Wildfotune" (click)="toMainPage($event)">
          @if (halloween.linkMenuEnabled) {
            <div class="lottie-logo">
              <app-lottie
                [loop]="true"
                [width]="isMobile() ? '60px' : '82px'"
                [path]="'/assets/lottie/jsons/halloween/logo.json'"
                [assetsPath]="'/assets/lottie/images/halloween/logo/'">
              </app-lottie>
            </div>
          } @else {
            <img src="/assets/svg/logo.svg" alt="Wildfortune logo">
            <span>Wildfotune</span>
          }
        </a>
      </div>
      <div class="right">
        <ng-container>
          @if (user.auth) {
            <app-header-authorized></app-header-authorized>
          }
          @if (!user.auth) {
            <app-header-not-authorized></app-header-not-authorized>
          }
        </ng-container>
      </div>
    </div>
  </div>
</header>
