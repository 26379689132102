import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginFormController } from '../../../helpers/user/login-form-controller';
import { Router, RouterLink } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { EnvironmentService } from '../../../services/environment.service';
import {InstallAppService} from '../../../services/install-app.service';
import {ModalService} from '../../../modal-v2/modal.service';
import { SeoService } from '../../../services/seo.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { PreloaderComponent } from '../../components/preloader/preloader.component';
import { PasswordShowDirective } from '../../directives/password-show.directive';
import { SetValueAutofillDirective } from '../../directives/set-input-value-ios.directive';
import { FormInputComponent } from '../../../modules/form-controls/form-input/form-input.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, NgClass, FormInputComponent, SetValueAutofillDirective, PasswordShowDirective, PreloaderComponent, RouterLink, RouterLinkDirective, AsyncPipe, TranslatePipe]
})
export class LoginFormComponent implements OnInit {

  @Output()
  public close$: EventEmitter<boolean> = new EventEmitter<boolean>();


  /**
   * Controller for current form
   */
  public controller: LoginFormController = new LoginFormController();

  constructor(
    private _modal: ModalService,
    private _router: Router,
    public user: UserService,
    private _gtm: GoogleTagManagerService,
    public env: EnvironmentService,
    public installApp: InstallAppService,
    public seo: SeoService
  ) { }

  ngOnInit() {
    this.controller.loggedIn$.subscribe(user => {
      this._gtm.signInSuccess(user.id);
    });
  }

  async openRestorePasswordModal() {
    const component = await import('../../../modal-v2/components/lazy/restoration-instruction-form/restoration-instruction-form.component');
    await this._modal.openLazy(component?.RestorationInstructionFormComponent);
  }

  onSignUpClick() {
    this._router.navigateByUrl('/register').then();
  }

  onClose() {
    this.close$.next(true);
  }
}
