
/**
 * Available modals from url
 */

export enum ModalsFromUrlList {
  LOGIN = 'login',
  REGISTRATION = 'registration'
}

/**
 * Modals by string from url
 */
export const  ModalsString = {
  login : {
    component: 'SIGN_IN',
    options: {
      disableOverlayClosing: true,
      template: 'CLEAR'
    }
  },
  registration: {
    component: 'SIGN_UP',
    options: {
      disableOverlayClosing: true,
      template: 'CLEAR'
    }
  }
};
