import {Component, forwardRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UserService } from '../../../core/services/user/user.service';
import {HalloweenService, WHEEL_BONUSES} from './halloween.service';
import {HalloweenWheelComponent} from './halloween-wheel/halloween-wheel.component';
import {catchError, delay, first, switchMap, tap} from 'rxjs/operators';
import {ScrollService} from '../../../core/services/scroll.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable, of} from 'rxjs';
import {EnvironmentService} from '../../../core/services/environment.service';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { SafePipe } from '../../../core/shared/pipes/safe.pipe';
import { FooterComponent } from '../../../core/shared/layout/footer/footer.component';
import { BottomNavigationComponent } from '../../../core/shared/layout/bottom-navigation/bottom-navigation.component';
import { AccordionDirective } from '../../../core/shared/directives/accordion.directive';
import { FontByBlockSizeDirective } from '../../../core/shared/directives/font-by-block-size.directive';
import { TournamentPreviewComponent } from '../../../core/shared/layout/tournament-preview/tournament-preview.component';
import { RouterLink } from '@angular/router';
import { RouterLinkDirective } from '../../../core/shared/directives/router-link.directive';
import { GamePreviewComponent } from '../../../core/shared/layout/game-preview/game-preview.component';
import { SliderComponent } from '../../../core/modules/slider/slider.component';
import { HalloweenTimerComponent } from './halloween-timer/halloween-timer.component';
import { NgStyle, NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';
import { SideNavComponent } from '../../../core/shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from '../../../core/shared/layout/header/header.component';
import {HalloweenBloodBtnComponent} from '../shared/components/halloween-blood-btn/halloween-blood-btn.component';
import {LottieComponent} from '../../../core/standalone/lottie/lottie.component';
import {BreakpointsService} from '../../../core/services/breakpoints.service';
import {CopyService} from '../../../core/services/copy.service';
import {isMobile} from '../../../core/helpers/device';

export const showHide = trigger('showHide', [
  state('void', style({
    opacity: 0,
    transform: 'scale(0.6)'
  })),
  transition('void <=> *', animate('0.2s cubic-bezier(0.465, 0.183, 0.153, 0.946)'))
]);

export const LAST_BONUS_ID = 'last-bonus';
export const LAST_WELCOME_BONUS_SPINNED = '--last-welcome-bonus-spinned';

@Component({
    selector: 'app-halloween',
    templateUrl: './halloween.component.html',
    styleUrls: ['./halloween.component.scss'],
    animations: [showHide],
    standalone: true,
  imports: [HeaderComponent, forwardRef(() => SideNavComponent), NgStyle, HalloweenTimerComponent, HalloweenWheelComponent, SliderComponent, GamePreviewComponent, NgTemplateOutlet, RouterLinkDirective, RouterLink, TournamentPreviewComponent, NgClass, FontByBlockSizeDirective, AccordionDirective, BottomNavigationComponent, FooterComponent, AsyncPipe, SafePipe, TranslatePipe, HalloweenBloodBtnComponent, LottieComponent]
})
export class HalloweenComponent implements OnInit, OnDestroy {
  @ViewChild('wheelComponent') wheelComponent: HalloweenWheelComponent;

  /**
   * Summer offer
   */
  public promo$: Observable<any> = this.halloween.getPromo$();

  public onSpinClick = false;

  public isWelcomePrize: boolean;

  constructor(
    public user: UserService,
    public halloween: HalloweenService,
    public env: EnvironmentService,
    public breakpoint: BreakpointsService,
    private _scroll: ScrollService,
    public copy: CopyService
  ) {
  }

  ngOnInit() {
    this.halloween.currentPrize = null;
    this.halloween.lastWelcomeId = this.halloween.cookie.get(LAST_BONUS_ID);
  }


  ngOnDestroy() {
    this.halloween.currentPrize = null;
  }

  public onSpinWheel() {
    this.onSpinClick = true;
    if (this.halloween.isAbleToSpinLootbox()) {
      this._activateLootBoxAndSpin(Boolean(this.halloween.currentWelcomeBonus.lootbox));
    } else {
      this._spinWithWelcomeBonus();
    }
  }

  private _activateLootBoxAndSpin(isWelcomeLootbox: boolean) {
    const lootboxId = isWelcomeLootbox ? this.halloween.currentWelcomeBonus.lootbox.id : this.halloween.lootboxPromoBonus.id;
    this.isWelcomePrize = isWelcomeLootbox;
    this.halloween.lootbox.activateLootbox(lootboxId).pipe(
      switchMap((activatedLootbox) => {
        const fsCount = this.halloween.bonuses.resolveBonusAttributes(
          activatedLootbox.bonuses[0].attributes,
        ).freespins_count;
        const findIndex = WHEEL_BONUSES.findIndex(item => item?.prize === fsCount);
        const findImage = WHEEL_BONUSES[findIndex]?.image || WHEEL_BONUSES[1]?.image;
        const spinDegree = 4321 - (30 * findIndex);
        return this.wheelComponent.onSpin$(spinDegree).pipe(
          first(),
          delay(6500),
          tap(() => this._setLootboxPrize(activatedLootbox, findImage)),
          tap(() => {
            this.halloween.handleWelcomeSpin(true);
          })
        );
      }),
      catchError(() => {
        this.onSpinClick = false;
        return of(false);
      })
    ).subscribe();
  }

  private _spinWithWelcomeBonus() {
    this.isWelcomePrize = true;
    this.wheelComponent.onSpin$(4321).pipe(
      first(),
      delay(6500),
      tap(() => {
        const prizeImage = WHEEL_BONUSES?.find(w => w.prize === 'secret')?.image;
        const fs = this.halloween.currentWelcomeBonus?.fs?.freespins_total;
        let cash;
        if (this.halloween.currentWelcomeBonus?.cash) {
          cash = `${this.halloween.currentWelcomeBonus?.cash?.amount} ${this.halloween.currentWelcomeBonus?.cash?.currency}`;
        }
        this.halloween.handleWelcomeSpin(false);

        this.halloween.currentPrize = { image: prizeImage, fs_count: fs, cash };
        this.onSpinClick = false;
      }),
    ).subscribe();
  }

  private _setLootboxPrize(activatedLootbox: any, image: string) {
    const fs = this.halloween.bonuses.resolveBonusAttributes(
      activatedLootbox.bonuses[0].attributes,
    ).freespins_count;
    this.halloween.currentPrize = {
      image,
      fs_count: fs,
    };
    this.onSpinClick = false;
  }


  public scrollToWheel() {
    requestAnimationFrame(() => this._scroll.scrollToElement(this._scroll.documentElement));
  }

  protected readonly isMobile = isMobile;
}
