<div class="wheel">
  <div class="wheel__wheel">
    <div class="prizes">
      <img #prizesWheel [src]="wheelPrizesImage || '/assets/img/promo/halloween/wheel/prizes.png'" alt="">
    </div>
    <div class="divider">
      <img src="/assets/img/promo/halloween/wheel/divider.png" alt="">
    </div>
    <div class="center">
      <img src="/assets/img/promo/halloween/wheel/center.png" alt="">
    </div>
  </div>
</div>
