import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {showHide} from '../../modal-animations';
import {Modal} from '../../modal';

@Component({
    selector: 'app-modal-template-large',
    templateUrl: './modal-template-large.component.html',
    styleUrls: ['./modal-template-large.component.scss'],
    animations: [showHide],
    standalone: true
})
export class ModalTemplateLargeComponent  extends Modal implements OnInit {

  @ViewChild('content', { static: true, read: ViewContainerRef }) private contentRef: ViewContainerRef;

  constructor() {
    super();
  }

  ngOnInit() {}

}
