import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

export enum AccountPage {
  DEPOSIT = 'deposit',
  CASHOUT = 'cashout',
  BALANCE = 'balance',
  BONUSES = 'bonuses',
  HISTORY = 'history',
  DOCUMENTS = 'documents',
  INFO = 'user-account',
  RESPONSIBLE_GAMING = 'responsible-gaming',
  ACCOUNT = 'account',
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private _cashierLinks = [
    {url: '/profile/deposit', img: '/assets/img/categories/deposit.png', tab: 'h.deposit'},
    {url: '/profile/cashout', img: '/assets/img/categories/withdraw.png', tab: 'h.cashout'},
    {url: '/profile/history', img: '/assets/img/categories/history.png', tab: 'h.history'},
  ];

  private _settingsLinks = [
    {url: '/profile/account', img: '/assets/img/categories/account.png', tab: 'h.account'},
    {url: '/loyalty-program', img: '/assets/img/categories/loyalty.png', tab: 'h.loyalty'},
    {url: '/tournaments', img: '/assets/img/categories/tournament.png', tab: 'h.tournaments'},
  ];

  /**
   * Data for all account pages
   */
  private _pageData = {
    [AccountPage.DEPOSIT]: {title: 'h.deposit', links: this._cashierLinks},
    [AccountPage.CASHOUT]: {title: 'h.cashout', links: this._cashierLinks},
    [AccountPage.BALANCE]: {title: 'h.balance', links: this._cashierLinks},
    [AccountPage.BONUSES]: {title: 'h.bonuses', links: this._cashierLinks},
    [AccountPage.HISTORY]: {title: 'h.history', links: this._cashierLinks},
    [AccountPage.DOCUMENTS]: {title: 'h.documents', links: this._settingsLinks},
    [AccountPage.INFO]: {title: 'h.player-info', links: this._settingsLinks},
    [AccountPage.RESPONSIBLE_GAMING]: {title: 'h.res-gaming', links: this._settingsLinks},
    [AccountPage.ACCOUNT]: {title: 'h.account', links: this._settingsLinks},
  };

  /**
   * Current account page
   */
  private _page: AccountPage;

  constructor(
    private _router: Router
  ) {
    this._handleRoute();
  }

  /**
   * Returns current page data
   */
  get pageData(): {title: string, links: any[]} {
    return this._pageData[this._page] || null;
  }

  /**
   *
   */
  get settingsLinks() {
    return this._settingsLinks;
  }

  /**
   * Set current page title
   *
   * @param page
   */
  setPage(page: AccountPage) {
    this._page = page;
  }

  /**
   * Handle routes change for detecting current account page
   *
   * @private
   */
  private _handleRoute() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd && event.url.includes('/profile/')),
      tap((event: NavigationEnd) => {
        const page = /\/profile\/(.+?)($|\?)/.exec(event.url)[1];
        this.setPage(page as AccountPage);
      })
    ).subscribe();
  }
}
