import {
  Directive,
  HostListener, Inject,
  Input, OnDestroy
} from '@angular/core';
import { PlatformService } from '../../services/platform.service';
import { from, of } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  filter,
  pairwise,
  startWith
} from 'rxjs/operators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';

export interface IMoveFieldData {
  name: string;
  maxLength: number;
  isSelect?: boolean;
}

@UntilDestroy()
@Directive({
    selector: '[moveNextField]',
    standalone: true
})
export class MoveNextFieldDirective implements OnDestroy {
  /**
   * For inputs from this list logic be working (use for find next input and focus)
   */
  @Input() controlsList: IMoveFieldData[] = [];

  /**
   * Form for use if not host component
   */
  @Input() form: UntypedFormGroup;

  constructor(
    private _platform: PlatformService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._initNextFieldLogic();
  }

  ngOnDestroy() {
  }

  /**
   * Init next field logic
   * @private
   */
  private _initNextFieldLogic() {
    of(true).pipe(
      delay(100),
      filter(() => Boolean(this._platform.isBrowser && this.form && this.controlsList?.length && !!!this._document.querySelector('app-select#state'))),
      map(() => this.form),
      switchMap((form: UntypedFormGroup) => from(Object.entries(form.controls))),
      tap(control => this._subscribeValueChange(control))
    ).subscribe();
  }

  /**
   * Subscribe valueChange and focus next field
   * @param control
   * @private
   */
  private _subscribeValueChange(control: [string, AbstractControl]) {
    const allInputs = Array.from(this._document.querySelectorAll('input'))
      .filter(input => this.controlsList?.some(c => input?.classList?.contains(c?.name)));

    control[1].valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      startWith(''),
      pairwise(),
      tap(([prev, curr]) => {
        const controlSettingIndex = this.controlsList?.findIndex(c => c?.name === control[0]);
        if (
          (control[1]?.valid && !this.controlsList[controlSettingIndex]?.isSelect && curr?.length === this.controlsList[controlSettingIndex]?.maxLength) ||
          (this.controlsList[controlSettingIndex]?.isSelect && curr?.length - prev?.length >= 2)
        ) {
          const nextFocusInput = allInputs?.find(input => this.controlsList?.some(
            c => input?.classList?.contains(this.controlsList[controlSettingIndex + 1] && this.controlsList[controlSettingIndex + 1]?.name))
          );

          if (nextFocusInput) {
            //For 100% focus input on IOS and show keyboard
            const timeout = 100;
            // Align temp input element approximately where the input element is
            // so the cursor doesn't jump around
            const tempEl = this._document.createElement('input');
            tempEl.style.position = 'absolute';
            tempEl.style.top = (nextFocusInput.offsetTop + 7) + 'px';
            tempEl.style.left = nextFocusInput.offsetLeft + 'px';
            tempEl.style.height = '0';
            tempEl.style.opacity = '0';
            // Put this temp element as a child of the page <body> and focus on it
            this._document.body.appendChild(tempEl);
            tempEl.focus();

            // The keyboard is open. Now do a delayed focus on the target element
            setTimeout(() => {
              nextFocusInput.focus();
              nextFocusInput.click();
              // Remove the temp element
              this._document.body.removeChild(tempEl);
            }, timeout);
          }
        }
      })
    ).subscribe();
  }

  @HostListener('keydown.enter', ['$event']) onKeyDown(event: any) {

    if (this._platform.isBrowser) {

      const allInputs = this._document.querySelectorAll('input');
      const cvvInput = this._document.querySelector('.cvv');
      const phoneInput = this._document.querySelector('.no-phone');
      const submitBtn = this._document.querySelector('button[type=submit]');

      allInputs?.forEach((item: HTMLInputElement, index: number) => {
        if (event.target === item && index === allInputs?.length - 1) (submitBtn as HTMLInputElement)?.click();

        if (event.target === item && event.target === cvvInput && phoneInput) {
          allInputs[index + 2] && allInputs[index + 2]?.focus();
        } else if (event.target === item) {
          allInputs[index + 1] && allInputs[index + 1]?.focus();
        }
      });

      if (event.target === cvvInput || event.target === phoneInput) {
        (submitBtn as HTMLInputElement)?.click();
      }
    }
  }
}
