import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';

@Component({
    selector: 'app-bonus-cancel-confirmation',
    templateUrl: './bonus-cancel-confirmation.component.html',
    styleUrls: ['./bonus-cancel-confirmation.component.scss'],
    standalone: true,
    imports: [TranslatePipe]
})
export class BonusCancelConfirmationComponent implements OnInit, ModalContent {

  /**
   * Link to modal window
   */
  public modal: ModalRef;

  constructor() { }

  ngOnInit() {
  }

}
