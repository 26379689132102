<div class="tournament--wrapper">
  @if (tournament.closed || tournament.active) {
    <div class="tournament tournament--desktop"
      [style.backgroundColor]="tournament.color"
      [ngClass]="{'tournament--vip': tournament.vip}"
      >
      <div class="tournament__content">
        <div class="tournament__top">
          <div class="tournament__title" [innerHTML]="tournament?.title | safe:'html'"></div>
          <div class="tournament__description">
            <span>{{ 't.prize-pool' | translate }}:</span>&nbsp;@if (tournament?.prizePool) {
            <span [innerHTML]="tournament?.prizePool | safe: 'html'"></span>
          }
        </div>
      </div>
      <div class="tournament__time">
        <div class="tournament__time_wrapper">
          @if (tournament.closed) {
            <i class="icon icon-tournaments"></i>
          }
          <div class="tournament__time-label">{{tournament?.timeLabel}}</div>
        </div>
        @if (tournament?.time) {
          <app-tournament-time [timeLeft]="tournament?.time"></app-tournament-time>
        }
      </div>
      <div class="tournament__actions">
        @if (user.auth || tournament?.alwaysRedirectToTournament) {
          <a class="btn btn--filled-primary big" [routerLink]="['/', 'tournaments', tournament?.link]">
            {{ (tournament.active ? 't.participate' :  't.view-results') | translate}}
          </a>
        }
        @if (!user.auth && !tournament?.alwaysRedirectToTournament && !env.isRestrictSignUp) {
          <a
            class="btn btn--filled-primary big" [routerLink]="'/register'">
            {{ (tournament.active && !tournament.vip ? 't.register-take-part' : tournament.active && tournament.vip ? 't.join-gang' : 't.sign-up') | translate}}
          </a>
        }
      </div>
    </div>
    <img class="tournament__image" [src]="tournament.image | safe:'url'" [alt]="seo.metaTitle" [title]="tournament.title">
  </div>
}

@if (tournament.closed || tournament.active) {
  <div class="tournament tournament--mobile"
    [style.backgroundColor]="tournament.color"
    [ngClass]="{'tournament--vip': tournament.vip}"
    >
    <div class="tournament__top-mob">
      <div class="tournament__title" [innerHTML]="tournament?.title | safe:'html'"></div>
    </div>
    <div class="tournament__bottom-mob">
      <div class="tournament__description">
        <span>{{ 't.prize-pool' | translate }}:</span>&nbsp;@if (tournament?.prizePool) {
        <span [innerHTML]="tournament?.prizePool | safe: 'html'"></span>
      }
    </div>
    <div class="tournament__time">
      <div class="tournament__time_wrapper">
        @if (tournament.closed) {
          <i class="icon icon-tournaments"></i>
        }
        <div class="tournament__time-label">{{tournament?.timeLabel}}</div>
      </div>
      @if (tournament?.time) {
        <app-tournament-time [timeLeft]="tournament?.time"></app-tournament-time>
      }
    </div>
    <div class="tournament__actions">
      @if (user.auth) {
        <a class="btn btn--filled-primary big" [routerLink]="tournament?.link">
          {{ (tournament.active ? 't.participate' :  't.view-results') | translate}}
        </a>
      }
      @if (!user.auth && !env.isRestrictSignUp) {
        <a class="btn btn--filled-primary big" [routerLink]="'/register'">
          {{ (tournament.active && !tournament.vip ? 't.register-take-part' : tournament.active && tournament.vip ? 't.join-gang' : 't.sign-up') | translate}}
        </a>
      }
    </div>
  </div>
  <img class="tournament__image" [src]="tournament.imageMob | safe:'url'" [alt]="seo.metaTitle" [title]="tournament.title">
</div>
}

@if (tournament.upcoming) {
  <div class="tournament tournament--upcoming"
    [ngClass]="{'tournament--vip': tournament.vip}"
    >
    <img class="tournament--upcoming_image" [src]="tournament.image | safe:'url'" [alt]="seo.metaTitle" [title]="tournament.title">
    <div>
      <div class="tournament--upcoming_content">
        <div class="tournament__title" [innerHTML]="tournament?.title | safe:'html'"></div>
        <div class="tournament__description">
          <span>{{ 't.prize-pool' | translate }}:</span>&nbsp;@if (tournament?.prizePool) {
          <span [innerHTML]="tournament?.prizePool | safe: 'html'"></span>
        }
      </div>
      <div class="tournament--upcoming_custom">
        @if (!isPreLaunchGroup) {
          <div>
            <button class="btn btn--filled-secondary big" (click)="upcomingClickAction()">{{ (user.auth ? 't.get-notified' : tournament.vip ? 't.join-gang' : 't.register-take-part') | translate}}</button>
            <div class="tournament--upcoming_info">{{ 't.tournament-notified' | translate }}</div>
          </div>
        } @else {
          <div>
            <div class="tournament--upcoming_info--bold">{{ 't.great' | translate }}!</div>
            <div class="tournament--upcoming_info">{{ 't.tournament-email' | translate }}</div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
}

@if (tournament.vip) {
  <span class="tournament--vip__label">VIP</span>
}
</div>
