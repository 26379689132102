<div class="selector" (click)="open()">
  @if (control.value) {
    <img class="selector__img" [src]="'/assets/svg/flags/' + control.value + '.svg'" alt="">
  }
  @if (countryName) {
    <span class="selector__label">{{ control.value }}</span>
  }
  @if (showArrow) {
    <i class="arrow down"></i>
  }
</div>

<form class="countries" [ngClass]="{open: isOpen}" ClickOutside (clickOutside)="close()" [formGroup]="searchForm">
  <input formControlName="query" class="search" type="text" [placeholder]="'t.search' | translate">
  <div class="list">
    @for (country of filteredCountryList; track country) {
      <div class="list__item" (click)="select(country)">
        <img class="selector__img" [src]="'/assets/svg/flags/' + country.short + '.svg'" alt="">
        <span class="selector__label">{{ country.name }}</span>
      </div>
    }
  </div>
</form>
