<div class="modal">
  <div class="modal__content">
    <div class="window">
      <i class="window__close icon-close" (click)="modal.close()"></i>
      <div class="missed-fields">
        <div class="missed-fields__title">{{'t.enter-valid-details' | translate}}</div>
        <div class="missed-fields__description">{{ 't.sign-up-subtitle' | translate }}</div>

  <ng-container *ngIf="controller.fields | async as fields">
    <form class="form" *ngIf="controller.formForModal"
          [formGroup]="controller.formForModal"
          (ngSubmit)="controller.submit(controller.formForModal)"
          moveNextField [form]="controller.formForModal" [controlsList]="controlList"
    >
      <ng-container *ngFor="let field of fields">
        <ng-container [ngSwitch]="true">
          <app-form-input
            #textInput
            *ngSwitchCase="field.type === FieldType.TEXT"
            [control]="controller.formForModal.get(field.field)"
            [label]="field.label | translate"
            (updateErrors$)="controller.updateControlErrors(field.field, textInput)"
            class="input-item"
          >
            <div class="suggestion-list" *ngIf="field.field === 'address'" [ngClass]="{'visible': controller.addressesSuggestion && controller.addressesSuggestion.length > 0}">
              <div class="suggestion-list__item" *ngFor="let suggestion of controller?.addressesSuggestion" (click)="controller.selectSuggestion(suggestion, 'address')">
                <span>{{ suggestion?.formatted }}</span>
              </div>
            </div>

            <div class="suggestion-list" *ngIf="field.field === 'city'" [ngClass]="{'visible': controller.citiesSuggestion && controller.citiesSuggestion.length > 0}">
              <div class="suggestion-list__item" *ngFor="let city of controller?.citiesSuggestion" (click)="controller.selectSuggestion(city, 'city')">{{ city?.city }}</div>
            </div>

            <div class="suggestion-list" *ngIf="field.field === 'postal_code'" [ngClass]="{'visible': controller.zipCodeSuggestion && controller.zipCodeSuggestion.length > 0}">
              <div class="suggestion-list__item" *ngFor="let suggestion of controller?.zipCodeSuggestion" (click)="controller?.selectSuggestion(suggestion, 'postal_code')">
                <span>{{ suggestion?.postcode }}</span>
              </div>
            </div>

            <input
              (focus)="controller.onFocus($event, field.field); controller.resetSuggestionByType(field.field)"
              (blur)="controller.resetSuggestionByType(field.field); controller.updateControlErrors(field.field, textInput)"
              [formControlName]="field.field"
              class="input input--simple"
              type="text"
              [mask]="userInfo.isCA && field?.field === 'postal_code' ? 'S0S 0S0' : (field?.mask || null)" [dropSpecialCharacters]="!(userInfo.isCA && field?.field === 'postal_code')" [validation]="false"
              [class]="field.field"
              [id]="field.field"
              [ngClass]="{uppercase: userInfo.isCA && field?.field === 'postal_code'}">

          </app-form-input>
          <app-form-input
            *ngSwitchCase="field.type === FieldType.PHONE"
            [control]="controller.formForModal.get(field.field)"
          >
            <app-phone-form [showLabel]="!userInfo.isFR" [usePrefix]="field?.field !== 'mobile_phone_without_dial_code'" [formControlName]="field.field" [id]="field.field"></app-phone-form>
          </app-form-input>
          <app-form-input
            *ngSwitchCase="field.type === FieldType.DATE"
            [control]="controller.formForModal.get(field.field)"
            [label]="('t.date-of-birth' | translate) + ' (DD/MM/YYYY)'"
            class="input-item"
          >
            <input
              (focus)="controller.onFocus($event, field.field)"
              [formControlName]="field.field"
              class="input input--simple"
              [ngClass]="[field.field]"
              type="text"
              [id]="field.field"
              appDobMask
              NumberOnly
            >
          </app-form-input>
          <ng-container *ngIf="!userInfo?.isFR">
            <app-form-input
              class="input-item"
              *ngSwitchCase="field.type === FieldType.SELECT"
              [control]="controller.formForModal.get(field.field)">
              <app-select *ngIf="field.options | async as options" [formControlName]="field.field" [id]="field.field" class="select">
                <app-select-header class="select__header">{{ field.label | translate }}</app-select-header>
                <app-select-dropdown (focus)="controller.onFocus($event, field.field)" class="select__dropdown">
                  <app-select-option class="select__option" *ngFor="let option of options" [value]="option[0]">{{ field.field === 'country' || field.field === 'state' ? option[1] : option[1] | translate }}</app-select-option>
                </app-select-dropdown>
              </app-select>
            </app-form-input>
          </ng-container>
          <app-form-input *ngSwitchCase="field.type === FieldType.CHECKBOX" class="input-item" [control]="controller.formForModal.get(field.field)">
            <label class="checkbox">
              <input type="checkbox" [formControlName]="field.field" [id]="field.field">
              <span class="checkbox__view"></span>
              <span class="checkbox__label">{{ field.label | translate }}</span>
            </label>
          </app-form-input>

          <app-form-input class="radio" *ngSwitchCase="field.type === FieldType.RADIO" [control]="controller.formForModal.get(field.field)">
            <ng-container *ngIf="field.options | async as options">
              <div *ngIf="field.options | async as options" class="radio">
                <label *ngFor="let option of options">
                  <input
                    type="radio"
                    [value]="option[0]"
                    [formControlName]="field.field"
                    [id]="field.field"
                    [ngClass]="[field.field]"
                  />
                  <span>{{option[1] | translate}}</span>
                </label>
              </div>
            </ng-container>
          </app-form-input>
        </ng-container>
      </ng-container>

      <div class="submit-container">
        <ng-container *ngIf="!controller.loading">
          <button class="btn btn--filled-primary" type="submit">{{'t.start-win' | translate}}</button>
        </ng-container>

        <app-preloader *ngIf="controller.loading"></app-preloader>
      </div>
      </form>
      </ng-container>
      </div>
    </div>
  </div>
</div>

