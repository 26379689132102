import { Injectable } from '@angular/core';
import { ToastMessageService } from '../modules/toast-message/toast-message.service';

@Injectable({
  providedIn: 'root'
})
export class CopyService {

  constructor(
      private _toastMessage: ToastMessageService,
  ) { }

  /**
   * Copy adress code by click
   * @param el
   * @param code
   */
  copy(el, code) {
    try {
      if ((navigator as any).clipboard) {
        (navigator as any).clipboard.writeText(code);
      } else if ((window as any).clipboardData) {
        (window as any).clipboardData.setData('text', code); // IE
      } else {
        this._copyToClipboard(el); // other browsers, iOS, Mac OS
      }
      this._toastMessage.success('t.copied-to-clipboard');
    } catch (e) {
      this._toastMessage.error('t.browser-not-support'); // copy failed.
    }
  }

  /**
   * Copy To clipboard for other browsers
   * @param el
   */
  private _copyToClipboard(el: HTMLInputElement) {
    const oldContentEditable = el.contentEditable;
    const oldReadOnly = el.readOnly;
    try {
      el.contentEditable = 'true'; // specific to iOS
      el.readOnly = false;
      this._copyNodeContentsToClipboard(el);
    } finally {
      el.contentEditable = oldContentEditable;
      el.readOnly = oldReadOnly;
    }
  }

  /**
   * Copy node contents to clipboard for IOS
   * @param el
   */
  private _copyNodeContentsToClipboard(el: HTMLInputElement) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(el);
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);
    document.execCommand('copy');
  }

}
