import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {UserService} from '../../../../services/user/user.service';
import {TranslationService} from '../../../../shared/translation/translation.service';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';


@Component({
    selector: 'app-game-not-available',
    templateUrl: './game-not-available.component.html',
    styleUrls: ['./game-not-available.component.scss'],
    standalone: true,
    imports: [TranslatePipe]
})
export class GameNotAvailableComponent implements OnInit, ModalContent {

  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  public translateWithDynamicVariable: string;

  constructor(
    public user: UserService,
    private _translate: TranslationService
  ) { }

  ngOnInit() {
    this.translateWithDynamicVariable = this._translate.translate('t.game-not-support', {currency: this.user.info.currency});
  }

}
