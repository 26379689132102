<div class="popup">
  <div class="popup__title">{{ 't.game-not-available' | translate }}</div>
  <div class="popup__text">
    @if (!modal.options?.data?.currencyList) {
      {{ 't.game-not-found' | translate }}
    }

    @if (modal.options?.data?.currencyList) {
      <div>{{ translateWithDynamicVariable }}</div>
      <br>
        <div>{{ 't.supports-only' | translate }}:
          <br>
            @for (currency of modal.options?.data?.currencyList; track currency; let i = $index) {
              <strong>@if (i !== 0) {
                <span>,</span>
              } {{ currency }}</strong>
            }
          </div>
        }
      </div>
      <div class="popup__actions">
        <button class="btn big btn--primary" (click)="modal.close()">Ok</button>
      </div>
    </div>
