import { NgModule } from '@angular/core';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from './select-header/select-header.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectComponent } from './select.component';

@NgModule({
  imports: [
    SelectComponent,
    SelectOptionComponent,
    SelectHeaderComponent,
    SelectDropdownComponent
  ],
  exports: [
    SelectComponent,
    SelectOptionComponent,
    SelectHeaderComponent,
    SelectDropdownComponent
  ]
})
export class SelectModule { }
