import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../language/language.service';
import { Router } from '@angular/router';
import {CmsApiService} from '../api/cms-api.service';
import {ToastMessageService} from '../../modules/toast-message/toast-message.service';
import { LocalHistoryService } from '../local-history.service';
import {HostRequestsInterceptor} from '../../interceptors/host-requests.interceptor';
import {of} from "rxjs";
import {delay, tap} from "rxjs/operators";
import {ModalService} from '../../modal-v2/modal.service';

/**
 * Game mode list
 */
export enum GameMode {
  DEMO = 'demo',
  REAL = 'real'
}

@Injectable({
  providedIn: 'root'
})
export class GamesLauncherService {

  /**
   * Is game launched now
   */
  public gameLaunched: boolean;

  /**
   * Current launched game
   */
  public currentGame: any;

  constructor(
    private _user: UserService,
    private _language: LanguageService,
    private _modal: ModalService,
    private _router: Router,
    private _cmsApi: CmsApiService,
    private _toast: ToastMessageService,
    private _localHistory: LocalHistoryService,
    private _hostInterceptor: HostRequestsInterceptor
  ) { }

  /**
   * Returns iframe source for provided game
   *
   * @param game
   * @param demo
   */
  public gameSource(game: any, demo: boolean = false): {url: string, mode: GameMode} {
    const idObject = this.resolveGameId(game, demo);

    return {
      url: this._hostInterceptor.overrideUrl(`${environment.ss_host}/${this._language.current}/games/${game.externalId}/${idObject.id}`),
      mode: idObject.mode
    };
  }

  /**
   * Returns external id for provided game
   *
   * @param game
   * @param demo
   * @private
   */
  public resolveGameId(game: any, demo: boolean = false): {id: string, mode: GameMode} {
    try {
      if (!this._user.auth || demo || !this._user.info.currency || !game.externalData.real[this._user.info.currency]) {
        return {
          id: game.externalData.demo,
          mode: GameMode.DEMO
        };
      } else {
        return {
          id: game.externalData.real[this._user.info.currency].id,
          mode: GameMode.REAL
        };
      }
    } catch (e) {}
  }

  /**
   * Check if game available for current user
   *
   * @param game
   */
  public checkGameAvailability(game): boolean {
    if (game) {
      if (!this._user.auth && game.nodemo) {
        this._router.navigateByUrl('/register').then();
        return false;
      }

      if (this._user.auth && !game.externalData.real[this._user.info.currency]) {
        this._gameNotAvailablePopup({
          currencyList: Object.keys(game.externalData.real)
        });
        this._localHistory.redirectToPreviousUrl();
        return false;
      }

      if (!this._user.auth && game.live) {
        this._router.navigateByUrl('/register').then();
      }
      return true;
    }

    this._gameNotAvailablePopup({
      game: game && game.name ? game.name : ''
    });
    this._localHistory.redirectToPreviousUrl();
    return false;
  }

  /**
   * Show game not available popup
   *
   * @private
   */
  private _gameNotAvailablePopup(data: object = {}) {
    this._router.navigateByUrl('/games').then(() => {
      of(true).pipe(
        delay(300),
        tap(() => {
          this._modal.open('GAME_NOT_AVAILABLE', {
            data,
            closeAfterRouteChange: true
          });
        })
      ).subscribe();
    });
  }

  /**
   * Open login popup
   *
   * @private
   */
  private _loginPopup() {
    this._modal.open('SIGN_IN',{
      disableOverlayClosing: true,
      template: 'CLEAR'
    });
  }

  /**
   * Open game by external id
   *
   * @param game
   */
  openGameByExternalId(game) {
    this._cmsApi.gameList({'external_id[]': game.identifier}).subscribe(e => {
      if (e.data.gameList) {
        this._router.navigateByUrl('/en/play/' + e.data.gameList[0].slug, {
          state: {
            game: e.data.gameList[0]
          }
        });
      } else {
        this._toast.warning('This game is not available');
      }
    });
  }

  /**
   * Send Game open data for auto top (if user is auth)
   */
  public postGameOpen(gameId) {
    if (this._user.auth) {
      this._cmsApi.postGameOpen({user_id: this._user.info.id, game: gameId}, {country_short: this._user.info.country}).subscribe();
    }
  }
}
