import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { LocalHistoryService } from '../../../services/local-history.service';
import { FavoriteGamesService } from '../../../services/games/favorite-games.service';
import { DeviceDetectorService } from 'ngx-unificator/services';
import { UserService } from '../../../services/user/user.service';
import { LanguageService } from '../../../services/language/language.service';
import { GameMode, GamesLauncherService } from '../../../services/games/games-launcher.service';
import { BreakpointsService } from '../../../services/breakpoints.service';
import { EnvironmentService } from '../../../services/environment.service';
import { NgxTippyProps, NgxTippyModule } from 'ngx-tippy-wrapper';
import { TranslationService } from '../../translation/translation.service';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { switchMap, tap } from 'rxjs/operators';
import { DefaultImageType, DefaultImage } from '../../directives/default-image.directive';
import { CookieService } from 'ngx-unificator/services';
import { cookiePreviousClickedGameMode } from '../../../services/games/games.service';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { UserInfoService } from '../../../services/user/user-info.service';
import { of } from 'rxjs';
import {ModalService} from '../../../modal-v2/modal.service';
import { SeoService } from '../../../services/seo.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { ImgDimensionDirective } from '../../directives/img-dimension.directive';
import { JackpotBadgeComponent } from '../../components/jackpot-badge/jackpot-badge.component';
import { NgClass } from '@angular/common';
import { FaceliftService } from '../../../services/facelift.service';

@UntilDestroy()
@Component({
    selector: 'app-game-preview',
    templateUrl: './game-preview.component.html',
    styleUrls: ['./game-preview.component.scss'],
    standalone: true,
    imports: [NgClass, NgxTippyModule, JackpotBadgeComponent, ImgDimensionDirective, DefaultImage, RouterLink, RouterLinkDirective, TranslatePipe]
})
export class GamePreviewComponent implements OnInit, OnDestroy {

  /**
   * Current game
   */
  @Input() game: any = {};

  /**
   * Open without overlay
   */
  @Input() smallMode: boolean = false;

  /**
   * Input true for hide bottom info
   */
  @Input() hideInfo: boolean = false;

  /**
   * Game modes
   */
  public modes = {
    real: GameMode.REAL,
    demo: GameMode.DEMO,
  };

  /**
   * Toggle state for overlay
   */
  public isToggleOverlayForDevice: boolean;

  /**
   * Tippy tooltips props for tournament game
   */
  public tippyPropsTournament: NgxTippyProps = {
    content: this._translation.translate('t.tournament-game'),
    arrow: false
  };

  constructor(
    private _router: Router,
    private _localHistory: LocalHistoryService,
    private _translation: TranslationService,
    private _cookie: CookieService,
    private _gameLauncher: GamesLauncherService,
    private _gtm: GoogleTagManagerService,
    private _userInfo: UserInfoService,
    private _modal: ModalService,
    public favorite: FavoriteGamesService,
    public device: DeviceDetectorService,
    public user: UserService,
    public lang: LanguageService,
    public env: EnvironmentService,
    public breakPoint: BreakpointsService,
    public seo: SeoService,
    public facelift: FaceliftService,
  ) { }

  ngOnInit() {
    this.favorite.setFavoriteStatus(this.game);

    this.favorite.toggleFavorite$.pipe(
      untilDestroyed(this),
      tap(() => this.favorite.setFavoriteStatus(this.game))
    ).subscribe();
  }

  ngOnDestroy() {
  }

  get defaultImageType() {
    return DefaultImageType;
  }

  /**
   * Redirect to game
   */
  openGame(mode: string) {
    if (this._cookie.check(cookiePreviousClickedGameMode)) {
      this._cookie.delete(cookiePreviousClickedGameMode, '/');
    }
    this._cookie.set(cookiePreviousClickedGameMode, mode === GameMode.REAL ? mode : mode, null, '/');
    this._sendGameClickEvent(mode);
    if (this.user.auth) {
      this._checkRequiredFields(mode);
    } else {
      this._navigateToGameAndAddToLocalHistory(mode);
    }
  }


  /**
   * Send game click event
   * @param game
   * @param mode
   * @private
   */
  private _sendGameClickEvent(mode: string) {
    const gameIdData = this._gameLauncher.resolveGameId(this.game, mode === GameMode.DEMO);
    const gameId = gameIdData && gameIdData.id;
    if (gameId) {
      this._gtm.gameClickEvent(String(gameId));
    }
  }

  /**
   * Toggle overlay for devices
   */
  toggleOverlay() {
    if (this.smallMode) {
      this.openGame(this.modes.real);
    } else {
      if ((this.breakPoint.isTablet || this.breakPoint.isMobile)) {
        this.isToggleOverlayForDevice = !this.isToggleOverlayForDevice;
      } else {
        this.openGame(this.modes.real);
      }
    }
  }

  /**
   * Check required fields and trigger actions
   * @private
   */
  private _checkRequiredFields(mode: string) {
    this._userInfo.missingAttributesFor('deposit').pipe(
      untilDestroyed(this),
      switchMap((fields: any) => {
        if (fields.length) {
          const modal = this._modal.open('AUTH_FIELD_MISSED_UPDATE', {
            data: {
              context: 'deposit',
            },
            closeAfterRouteChange: true,
          });

          return modal.onResult();
        } else {
          return of(true);
        }
      }),
      tap((result) => {
        if (result) {
          this._navigateToGameAndAddToLocalHistory(mode)
        }
      })
    ).subscribe();
  }

  /**
   * Navigate to game and add to local history
   * @private
   */
  private _navigateToGameAndAddToLocalHistory(mode: string) {
    this._localHistory.addUrl('before-play', this._router.url);
    this._router.navigateByUrl('/' + this.lang.current + '/play/' + this.game.slug, {
      state: {
        game: this.game,
        mode: mode === GameMode.REAL ? mode : mode
      }
    }).then();
  }
}
