import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, of } from 'rxjs';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { catchError, filter, first, tap } from 'rxjs/operators';
import { PlatformService } from '../../services/platform.service';

@UntilDestroy()
@Directive({
    selector: '[ClassLoaded]',
    standalone: true
})
export class ClassLoadedDirective implements AfterViewInit, OnDestroy {

  constructor(
    private _el: ElementRef,
    private _platform: PlatformService
  ) { }

  ngAfterViewInit(): void {
    fromEvent(this._el.nativeElement, 'load').pipe(
      untilDestroyed(this),
      first(),
      catchError(error => of(error)),
      filter(() => this._platform.isBrowser),
      tap(() => {
        this._el.nativeElement.classList.add('loaded');
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
  }
}
