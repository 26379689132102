<div class="form">
  <div class="tabs">
    <div class="tabs__header">
      <!--      <div class="tabs__header-tab" [ngClass]="{active: currentTab === Tab.TERMS}" (click)="currentTab = Tab.TERMS">{{'t.terms-and-cond' | translate}}</div>-->
      <div class="tabs__header-tab" [ngClass]="{active: currentTab === Tab.POLICY}" (click)="currentTab = Tab.POLICY">{{'t.privacy-policy' | translate}}</div>
    </div>

    <div [hidden]="currentTab !== Tab.TERMS">
      @if (terms$ | async; as terms) {
        <div class="tabs__content">
          <div class="text" [innerHTML]="terms[0]?.Content | safe: 'html'"></div>
        </div>
      } @else {
        <app-preloader></app-preloader>
      }

    </div>

    <div [hidden]="currentTab !== Tab.POLICY">
      @if (policy$ | async; as policy) {
        <div class="tabs__content">
          <div class="text" [innerHTML]="policy[0]?.Content | safe: 'html'"></div>
        </div>
      } @else {
        <app-preloader></app-preloader>
      }

    </div>
  </div>

  <div class="submit-container">
    @if (!loading) {
      <button class="btn btn--simple-primary" (click)="decline()">{{'t.decline' | translate}}</button>
      <button class="btn btn--filled-primary" (click)="accept()">{{'t.accept' | translate}}</button>
    }
    @if (loading) {
      <app-preloader></app-preloader>
    }
  </div>
</div>
