import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { EnvironmentService } from '../../../services/environment.service';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonDataService } from '../../../services/common-data.service';
import { DisplayCurrencyPipe } from '../../pipes/display-currency.pipe';
import { NgClass } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-jackpot-badge',
    templateUrl: './jackpot-badge.component.html',
    styleUrls: ['./jackpot-badge.component.scss'],
    standalone: true,
    imports: [NgClass, DisplayCurrencyPipe]
})
export class JackpotBadgeComponent implements OnInit, OnDestroy {

  /**
   * Current game
   */
  @Input() game: any = {};

  /**
   * Small mode
   */
  @Input() smallMode: boolean;

  /**
   * Amount jackpot
   */
  public amount: string | number;

  /**
   * Currency for display
   */
  public currency: string;

  constructor(
    private _user: UserService,
    private _env: EnvironmentService,
    private _data: CommonDataService
  ) { }

  ngOnInit() {
    combineLatest([
      this._user.auth$,
      this._env.env$
    ]).pipe(
      untilDestroyed(this),
      tap(([auth, env]) => {
        if (auth) {
          this.currency = this._user.currentCurrency.currency;
          this.amount = this._data.subunitsToUnits(this.game.externalData.real[this._user.currentCurrency.currency].jackpot, this.currency);
        } else {
          this.currency = this.game.externalData.real[this._env.env.currency.short] ? this._env.env.currency.short : 'EUR';
          this.amount = this._data.subunitsToUnits((this.game.externalData.real[this._env.env.currency.short] || this.game.externalData.real['EUR']).jackpot, this.currency);
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
  }

}
