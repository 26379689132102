@if (countdownTimer$ | async; as countdownTimer) {
  <div [@showHide] class="banner"
    [ngClass]="{'sign-up': place === countdownTimerBannerPlace.SIGN_UP, 'deposit': place === countdownTimerBannerPlace.DEPOSIT}"
    >
    @if (place === countdownTimerBannerPlace.DEPOSIT) {
      @if (!showLoaderBtn) {
        <button  class="btn btn--primary big" (click)="buttonClick()">{{ 't.deposit' | translate }}</button>
      } @else {
        <app-preloader></app-preloader>
      }
    }
    <p class="banner__text">{{ 't.only-now' | translate }}:</p>
    <div class="banner__prize" [innerHTML]="countdownTimer?.PrizeTitle | safe: 'html'"></div>
    <div class="banner__prize-subtitle" [innerHTML]="countdownTimer?.PrizeSubtitle | safe: 'html'"></div>
    <app-countdown-timer [timeLeft]="countdownTimer?.Time" (timeFinished)="onBannerLoaded.emit(false)"></app-countdown-timer>
    <img ClassLoaded class="banner__img" [src]="countdownTimer?.Image" alt="Countdown timer image">
    @if (place === countdownTimerBannerPlace.DEPOSIT) {
      <img ClassLoaded class="banner__circle-img" src="/assets/img/countdown-dep.png" alt="Countdown deposit image">
    }
  </div>
}
