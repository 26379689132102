import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { UserService } from './user.service';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { StaticContentService } from '../static-content.service';
import { CookieService } from 'ngx-unificator/services';
import { shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { isNullOrUndefined } from '../../helpers/utils';
import {ModalService} from '../../modal-v2/modal.service';
import {SsPaymentsV2Service} from '../../vendor/ss-payments-v2/ss-payment.service';

export interface FieldDescriptor {
  field: string;
  once_set: boolean;
  type: string;
  inclusion?: {
    in?: any[]
  };
}

export interface FieldsResponse {
  contexts: {
    [key: string]: string[]
  };
  fields: FieldDescriptor[];
}

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  /**
   * List of user attributes that need to be filled for specific action
   */
  private _requiredFields$: Observable<FieldsResponse> = this._api.infoPlayerFields().pipe(
    shareReplay(1)
  );

  private _user: UserService;

  public isFullRegister$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private _api: SsApiService,
    private _modal: ModalService,
    private _staticContent: StaticContentService,
    private _cookie: CookieService,
    private _env: EnvironmentService,
    private _ssPayments: SsPaymentsV2Service
  ) {}

  /**
   * If user from FR or user acc is FR
   */
  public get isFR(): boolean {
    return this._env.env.country.short === 'fr' || (this._user.auth && this._user.info.country === 'FR');
  }

  /**
   * For avoiding circular dependency
   *
   * @param service
   */
  set userService(service: UserService) {
    this._user = service;
  }

  /**
   * Returns list of attribute names that not filled by user but required for some context
   *
   * @param userInfo
   * @param context
   */
  missingAttributesFor(context: string): Observable<FieldDescriptor[]> {
    return this._requiredFields$.pipe(
      first(),
      map(
        (fieldsConfig: FieldsResponse) => fieldsConfig.fields.filter(
          field => {
            if (context) {
              return fieldsConfig.contexts[context].includes(field.field)
                && (isNullOrUndefined(this._user.info[field.field]) || this._user.info[field.field] === '');
            }
          }
        )
      ),
      map(fields => this._filterRequiredFields(fields)),
    );
  }

  /**
   * Show modal window if user has missed auth fields
   *
   * @param userInfo
   */
  checkMissingAuthFields(userInfo) {
    if (userInfo.auth_fields_missed && userInfo.auth_fields_missed.length) {
      /**
       * If user missing only terms acceptance -> show terms acceptance modal
       */
      if (userInfo.auth_fields_missed.length === 1 && userInfo.auth_fields_missed.includes('terms_acceptance')) {
        this._modal.open('TERMS_ACCEPT', {
          disableOverlayClosing: true,
          template: 'CLEAR',
          data: {
            user: this._user,
            userInfo: this
          }
        });

      }
        /**
         * Else -> show AuthFieldMissed modal (email, currency, terms)
         */
      else {
        this._modal.open('AUTH_FIELD_MISSED', {
          disableOverlayClosing: true,
          template: 'CLEAR',
          data: {
            user: this._user,
            userInfo: this
          }
        });
      }
    } else {
      this._resolveBonusModal();
    }
  }

  /**
   * Update player info
   *
   * @param data
   * @param context
   */
  updatePlayerInfo(data: object, context?: string): Observable<any> {
    return this._api.patchPlayer({
      context,
      player: data
    }).pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Set new password for user
   *
   * @param data
   * @param context
   */
  changePassword(data: object, context?: string): Observable<any> {
    return this._api.putUsers({
      context,
      user: data
    });
  }

  /**
   * Confirm user account
   *
   * @param token
   */
  userConfirmation(token: string): Observable<any> {
    return this._api.usersConfirmation({
      confirmation_token: token
    });
  }

  /**
   * Auth provider confirmation (when sign up using social networks)
   *
   * @param token
   */
  authProviderConfirmation(token: string): Observable<any> {
    return this._api.authProvidersConfirmation({
      confirmation_token: token
    });
  }

  /**
   * Set user missed auth fields (when sign up using social networks)
   *
   * @param data
   */
  setMissedAuthFields(data: object): Observable<any> {
    return this._api.authProvidersUpdateDetails({
      user: data
    });
  }

  /**
   * Show bonus modal after login/registration
   *
   * @private
   */
  private _resolveBonusModal() {
    this._user.stats$.pipe(
      filter(() => !this._user.hasDeposit && !this._cookie.check('bonus-modal')),
      tap(() => this._cookie.set('bonus-modal', '1', 99, '/')),
      first(),
      switchMap(() => this._staticContent.item({
        slug: 'bonus-modal'
      })),
      filter((content: Array<any>) => !!content),
      first(),
      filter((content: Array<any>) => !!content.length),
      tap(content => this._openBonusAfterAuth(content))
    ).subscribe();
  }

  /**
   * Open bonus after auth
   * @private
   */
  private async _openBonusAfterAuth(content) {
    const component = await import('../../modal-v2/components/lazy/bonus-after-auth/bonus-after-auth.component');
    await this._modal.openLazy(component?.BonusAfterAuthComponent, {
      template: 'CLEAR',
      data: {
        content: content[0]
      }
    });
  }

  /**
   * Unlock user account
   *
   * @param token
   */
  userUnlock(token: string): Observable<any> {
    return this._api.usersUnlock({
      unlock_token: token
    });
  }

  /**
   * Get user country
   */
  get getUserCountry(): string {
    return this._env.env.country.short.toUpperCase() || (this._user.auth && this._user.info.country);
  }

  /**
   * Return true if user comes with affiliate link
   */
  get isUserWithAffiliateLink(): boolean {
    return Boolean(this._cookie.get('ptag'));
  }

  private _filterRequiredFields(fields: FieldDescriptor[]): FieldDescriptor[] {
    return (this.isFR ? fields.filter(field => field.field !== 'country') : fields)
      .filter(field => field?.field === 'state' && field?.inclusion?.in?.length || field?.field !== 'state')
  }

  /**
   * If user from CA or user acc is CA
   */
  public get isCA(): boolean {
    return this._env.env.country.short === 'ca' || (this._user.auth && this._user.info.country === 'CA');
  }

  /**
   * If user from SE or user acc is SE
   */
  public get isSE(): boolean {
    return this._env.env.country.short === 'se' || (this._user.auth && this._user.info.country === 'SE');
  }
}
