  import {Component, Input, OnInit} from '@angular/core';
import { TimeService } from '../../../../core/services/time.service';
import { BaseTimerComponent } from '../../../../core/helpers/base-timer.component';
import { TranslatePipe } from '../../../../core/shared/translation/translate.pipe';


@Component({
    selector: 'app-halloween-timer',
    templateUrl: './halloween-timer.component.html',
    styleUrls: ['./halloween-timer.component.scss'],
    standalone: true,
    imports: [TranslatePipe]
})
export class HalloweenTimerComponent extends BaseTimerComponent implements OnInit {
  @Input() isTryTomorrow: boolean;

  constructor(
    private _time: TimeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.resolveTimer();
  }

}
