import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { TimeService } from '../../../services/time.service';
import { TranslatePipe } from '../../translation/translate.pipe';


@UntilDestroy()
@Component({
    selector: 'app-tournament-time',
    templateUrl: './tournament-time.component.html',
    styleUrls: ['./tournament-time.component.scss'],
    standalone: true,
    imports: [TranslatePipe]
})
export class TournamentTimerComponent implements OnInit, OnDestroy {

  @Input() timeLeft: Date;
  timeLive: any;

  @Input('bloodMode') bloodMode = null;

  public bloodConfig = {};

  constructor(
    private _time: TimeService
  ) { }

  ngOnInit() {
    if (this.timeLeft) {
      this._time.currentDate$()
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.timeLive = this._time.timeDiff(this.timeLeft);
          this._resolveBloodMode();
        });
    } else {
      this.timeLive = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        daysLabel: 't.days',
        hoursLabel: 't.hrs',
        minLabel: 't.mins',
        secLabel: 't.secs'
      };
    }

  }

  ngOnDestroy(): void {
  }

  private _resolveBloodMode() {
    if (this.bloodMode) {
      this.timeLive.days = this.timeLive.days.toString().split('');
      this.timeLive.daysConfig = {};
      this.timeLive.days.forEach((day: string) => {
        this.timeLive.daysConfig[day] = {
          dotSize: Array.from(Array(Number(this._resolveDots(day))).keys())
        };
      });
    }
  }

  private _resolveDots(day: string) {
    let numDot = 0;
    switch (day) {
      case '1':
        numDot = 1;
        break;
      case '4':
        numDot = 2;
        break;
      case '0':
        numDot = 3;
        break;
      case '7':
        numDot = 1;
        break;
      default:
        numDot = 2;
        break;
    }
    return numDot;
  }
}
