<div class="accordion-button" [ngClass]="{'for-footer': isFooter,  facelift: facelift.showFacelift}">
  <div class="accordion">
    <div class="accordion__header" Accordion>
      <span class="accordion__header-label">{{'labl.t&c' | translate}}</span>
    </div>
    <div class="accordion__content">
      <ng-container *ngIf="text">
        <div class="accordion__content-container" [innerHTML]="text | safe:'html'"></div>
        <div [ngClass]="{'accordion__content_shadow--open': accordion.isOpen}" class="accordion__content_shadow"></div>
      </ng-container>

      <ng-content></ng-content>
      <div [ngClass]="{'accordion__content_shadow--open': accordion.isOpen}" class="accordion__content_shadow"></div>
    </div>
  </div>

  <div class="accordion-button__btn" (click)="accordion.toggle()" [ngClass]="{'accordion-button__btn--open': accordion.isOpen}">
    <span class="accordion-button__chevron" [ngClass]="{open: accordion.isOpen}"></span>
  </div>
</div>

