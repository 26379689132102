import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import {fromEvent} from 'rxjs';
import { filter } from 'rxjs/operators';
import { Location, NgClass, AsyncPipe } from '@angular/common';
import {showHide} from '../toast-message/animations';
import {RegisterFormController} from '../../helpers/user/register-form-controller';
import {PlatformService} from '../../services/platform.service';
import {CommonDataService} from '../../services/common-data.service';
import {BreakpointsService} from '../../services/breakpoints.service';
import {GoogleTagManagerService} from '../../services/google-tag-manager.service';
import {BodyService} from '../../services/body.service';
import {UserInfoService} from '../../services/user/user-info.service';
import {EnvironmentService} from '../../services/environment.service';
import {ToastMessageService} from '../toast-message/toast-message.service';
import {WindowService} from '../../services/window.service';
import {ERROR_MESSAGES} from '../../services/forms-error-handler.service';
import {ScrollService} from '../../services/scroll.service';
import { DeviceDetectorService } from 'ngx-unificator/services';
import { LocalHistoryService } from '../../services/local-history.service';
import { ModalService } from '../../modal-v2/modal.service';
import {BannersService} from '../../services/banners.service';
import { SeoService } from '../../services/seo.service';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { TranslatePipe } from '../../shared/translation/translate.pipe';
import { CountdownTimerBannerComponent } from '../../shared/components/countdown-timer-banner/countdown-timer-banner.component';
import { PreloaderComponent } from '../../shared/components/preloader/preloader.component';
import { RouterLinkDirective } from '../../shared/directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { AccordionDirective } from '../../shared/directives/accordion.directive';
import { CountrySelectorComponent } from '../../standalone/country-selector/country-selector.component';
import { PasswordShowDirective } from '../../shared/directives/password-show.directive';
import { MemoizeInvalidDirective } from '../../shared/directives/memoize-invalid.directive';
import { FormInputComponent } from '../form-controls/form-input/form-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import {isMobile} from '../../helpers/device';


let timesClicked = 0;


@UntilDestroy()
@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss'],
    animations: [showHide],
    standalone: true,
    imports: [NgClass, ReactiveFormsModule, FormInputComponent, MemoizeInvalidDirective, PasswordShowDirective, CountrySelectorComponent, AccordionDirective, RouterLink, RouterLinkDirective, PreloaderComponent, CountdownTimerBannerComponent, AsyncPipe, TranslatePipe, SafePipe]
})

export class RegisterFormComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  /**
   * For focus or blur active field, when toggle landscape
   */
  @Input() isLandscape: boolean;

  @Output()
  public close$: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public register$: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Controller for current register form
   */
  public controller: RegisterFormController = new RegisterFormController();

  public isSubmitedBonusCode = false;

  public isOpened = false;

  private _beforeScrollPos = 0;

  private readonly MAX_MOBILE_POINT = 767;

  public isCountdownTimerAvailable: boolean;

  public welcomeOfferInfo$ = this._banners.item({alias: 'register-welcome-offer'});

  public isMobile = isMobile;

  constructor(
    private _platform: PlatformService,
    private _renderer: Renderer2,
    public data: CommonDataService,
    public breakpoints: BreakpointsService,
    public seo: SeoService,
    private _gtm: GoogleTagManagerService,
    public body: BodyService,
    private _location: Location,
    public userInfo: UserInfoService,
    public env: EnvironmentService,
    private _toast: ToastMessageService,
    public window: WindowService,
    private _scroll: ScrollService,
    private _device: DeviceDetectorService,
    private _localHistory: LocalHistoryService,
    private _modals: ModalService,
    private _banners: BannersService
  ) {

  }

  get InputErrors() {
    return ERROR_MESSAGES;
  }

  ngOnInit() {
    this._addKeyupListener();
    this._handleBrowserBackButton();

    this.controller.registered$.pipe(
      tap(user => {
        this._gtm.signUpSuccess(user.id);
        this.register$.next(true);
      }),
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLandscape.previousValue !== undefined || changes.isLandscape.currentValue !== undefined) {
      changes.isLandscape.currentValue ? this._blurActiveField() : this._focusActiveField();
    }
  }

  ngAfterViewInit(): void {
    this._focusActiveField();
  }

  ngOnDestroy(): void {
    this.removeOverflowHiddenBody();
    this.controller.toggleRegistrationDataToStorage();
  }

  /**
   * Add overflow hidden to body
   */
  addOverflowHiddenBody() {
    if (this._platform.isBrowser && window.innerWidth <= this.MAX_MOBILE_POINT) {
      this._renderer.setStyle(document.body, 'overflow', 'hidden');
      this._renderer.addClass(document.body, 'sign-up');
    }
  }

  /**
   * remove overflow hidden to body
   */
  removeOverflowHiddenBody() {
    if (this._platform.isBrowser) {
      this._renderer.setStyle(document.body, 'overflow', '');
      this._renderer.removeClass(document.body, 'sign-up');
    }
  }

  /**
   * Set focus on active fiels
   *
   * @private
   */
  private _focusActiveField() {
    if (this._platform.isBrowser) {
      requestAnimationFrame(() => {
        const fieldElement: HTMLInputElement = document.querySelector('app-form-input input');

        if (fieldElement) {
          fieldElement.focus();
        }
      });
    }
  }

  /**
   * Set blur on active fiels
   *
   */
  private _blurActiveField() {
    if (this._platform.isBrowser) {
      requestAnimationFrame(() => {
        const fieldElement: HTMLInputElement = document.querySelector('app-form-input input');

        if (fieldElement) {
          fieldElement.blur();
        }
      });
    }
  }

  /**
   * Toggle focus active field
   *
   */
  public toggleFocusActiveField(isLandscape: boolean) {
    isLandscape ? this._blurActiveField() : this._focusActiveField();
  }

  /**
   * Handle browser back button
   *
   * @private
   */
  private _handleBrowserBackButton() {
    if (this._platform.isBrowser) {
      history.pushState(null, null, location.href);
      fromEvent(window, 'popstate').pipe(
        untilDestroyed(this),
        tap((e) => {
          this._location.back();
        })
      ).subscribe();
    }
  }

  /**
   * Add keyup listener for moving to next step by Enter
   *
   * @private
   */
  private _addKeyupListener() {
    if (this._platform.isBrowser && this._device.isDesktop()) {
      fromEvent(document, 'keypress').pipe(
        untilDestroyed(this),
        filter((event: any) => event.key === 'Enter'),
        tap(event => {
          this.controller.submitForm();
        })
      ).subscribe();
    }
  }

  deleteDepositeBonus() {
    this.controller.form.get('bonus_code').setValue('');
    this.controller.form.get('bonus_code').enable();
    this.isSubmitedBonusCode = false;
  }

  onBonuscodeFormSubmit() {
    this.isSubmitedBonusCode = true;
    const bonusControl = this.controller.form.get('bonus_code');
    bonusControl.disable();
    this._toast.success('t.bonus-code-added');
  }

  focusCode(codeInput: HTMLInputElement) {
    if (!this._platform.isBrowser) {
      return;
    }
    codeInput.focus();
    this.isOpened = !this.isOpened;

    if (this.isOpened) {
      this._beforeScrollPos = this.window.nativeWindow.scrollY;
      this._scroll.scrollToElement(codeInput, 500, true, 250);
    } else {
      codeInput.blur();
      this.window.nativeWindow.scrollTo({
        top: this._beforeScrollPos,
        behavior: 'smooth',
      });
    }
  }

  async onClose() {
    this._localHistory.removeUrl('before-login');

    timesClicked++;

    if (timesClicked >= 2) {
      this.close$.emit(true);
    } else {
      const component = await import('../../modal-v2/components/lazy/leave-register-modal/leave-register-modal.component');
      const modal = await this._modals.openLazy(component?.LeaveRegisterModalComponent, {
        template: 'CLEAR',
        disableOverlayClosing: true,
      });

      modal.onResult().subscribe(e => {
        if (e) {this.close$.emit(true);}
      });
    }
  }

}

