import { NavigationEnd, Router } from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import {ModalRef} from '../modal-v2/modal-ref';
import {ModalService} from '../modal-v2/modal.service';
import {ScrollService} from '../services/scroll.service';

/**
 * Available tabs types
 */
export enum TabsTypes {
  MODAL = 'modal',
  TAB = 'tab'
}

/**
 * Settings for TabPanelController
 */
export interface TabPanelControllerOptions {
  /**
   * Tab that will be open if tab param not exists
   */
  defaultTab: string;

  /**
   * Available tabs for current controller
   */
  availableTabs: any;

  /**
   * Query param name that will we used by this controller
   */
  queryParam: string;

  /**
   * Resolver observable
   */
  resolver?: Observable<boolean>;

  /**
   * Function that calls when route not resolved
   */
  rejectFn?: () => void;

  /**
   * Tab type modal or sidebar tab
   */
  tabType?: string;

  /**
   * Modals by string from url
   */
  modalsString?: any;
}

export class TabPanelController {
  /**
   * Is settings bar opened
   */
  private _isOpen: boolean;

  /**
   * Available tabs for current controller
   */
  private _availableTabs: any;

  /**
   * Query param name to resolve tab by url
   */
  private _queryParam;

  /**
   * Resolver observable
   */
  private _resolver: Observable<boolean>;

  /**
   * Function that calls when route not resolved
   */
  private _rejectFn: () => void;

  /**
   * Opened tab
   */
  private _currentTab: string;

  /**
   * If open() will be called without parameters this tab will be opened;
   */
  private readonly _defaultTab: string;

  /**
   * Tab type modal or sidebar tab
   */
  private _tabType: string;

  /**
   * Modals by string from url
   */
  private _modalsString: any;

  /**
   * Current opened modal
   */
  private _currentModal: ModalRef;

  /**
   * Link to global Router
   */
  private _router: Router = inject(Router);

  /**
   * Link to global ScrollService
   */
  private _scroll: ScrollService = inject(ScrollService);

  /**
   * Link to global ModalsService
   */
  private _modals: ModalService = inject(ModalService);

  constructor(options: TabPanelControllerOptions) {
    this._defaultTab = options.defaultTab;
    this._availableTabs = options.availableTabs;
    this._resolver = options.resolver;
    this._rejectFn = options.rejectFn;

    if (options.tabType) {
      this._tabType = options.tabType;
    } else {
      this._tabType = TabsTypes.TAB;
    }

    if (this._tabType === TabsTypes.MODAL && options.modalsString) {
      this._modalsString = options.modalsString;
    }

    if (options.queryParam) {
      this._queryParam = options.queryParam;
    }

    this._handleQueryParams();
  }

  /**
   * Access to _isOpen from outside
   */
  get isOpen() {
    return this._isOpen;
  }

  /**
   * Access to _current tab from outside
   */
  get currentTab() {
    return this._currentTab;
  }

  /**
   * Open settings menu
   *
   * @param tab
   */
  public open(tab = this._defaultTab) {
    if (this._resolver) {
      this._resolver.pipe(
        tap(resolved => {
          if (resolved) {
            this._currentTab = null;
            setTimeout(() => this._open(tab));
          } else {
            this._clearSettingsUrlParam();

            if (this._rejectFn) {
              this._rejectFn();
            }
          }
        })
      ).subscribe();
    } else {
      this._currentTab = null;
      setTimeout(() => this._open(tab));
    }
  }

  /**
   * Close settings menu
   */
  public close() {
    if (this._tabType === TabsTypes.MODAL) {
      if (this._currentModal) {
        this._currentModal.close();
      }
    } else {
      if (this._isOpen) {
        this._isOpen = false;
        this._scroll.UnblockScroll();
        this._clearSettingsUrlParam();
      }
    }
  }

  /**
   * Toggle settings menu
   */
  public toggle() {
    if (this._isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Open panel
   *
   * @param tab
   * @private
   */
  private _open(tab) {
    if (this._tabType === TabsTypes.MODAL) {
      this._currentModal = this._modals.open(this._modalsString[tab].component, this._modalsString[tab].options);
      this._currentModal.onResult().subscribe({
        complete: () => {
          this._clearSettingsUrlParam();
          this._currentModal = null;
        }
      });
    } else {
      this._isOpen = true;
      this._currentTab = tab;
      this._scroll.blockScroll();
      this._navigateToTab(tab);
    }
  }

  /**
   * Navigate to provided tab
   *
   * @param tab
   * @private
   */
  private _navigateToTab(tab) {
    const urlTree = this._router.parseUrl(this._router.url);
    if (urlTree.queryParams[this._queryParam] !== tab) {
      urlTree.queryParams[this._queryParam] = tab;
      this._router.navigateByUrl(urlTree).then();
    }
  }

  /**
   * Remove settings query param from url
   *
   * @private
   */
  private _clearSettingsUrlParam() {
    const urlTree = this._router.parseUrl(this._router.url);
    delete urlTree.queryParams[this._queryParam];

    this._router.navigateByUrl(urlTree).then();
  }

  /**
   * Handle query params and open tab depend on
   *
   * @private
   */
  private _handleQueryParams() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => this._router.parseUrl(event.url).queryParams[this._queryParam]),
      filter(settings => settings ? settings : this.close()),
      filter(tab => Object.values(this._availableTabs).includes(tab) ? tab : this._clearSettingsUrlParam()),
      tap(tab => {
        this.open(tab);
      })
    ).subscribe();
  }
}
