import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from '../../../services/language/language.service';
import { EnvironmentService } from '../../../services/environment.service';
import { OffersService } from '../../../../page/offers/offers.service';
import { SeoService } from '../../../services/seo.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import {InstallAppService} from '../../../services/install-app.service';

@Component({
    selector: 'app-bottom-navigation',
    templateUrl: './bottom-navigation.component.html',
    styleUrls: ['./bottom-navigation.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkDirective, AsyncPipe, TranslatePipe]
})
export class BottomNavigationComponent implements OnInit, OnDestroy {

  /**
   * Social footer content from CMS
   */
  public social$ = this._offers.item({ slug: 'telegram-viber-promo' });

  constructor(
    public lang: LanguageService,
    public env: EnvironmentService,
    public seo: SeoService,
    public installApp: InstallAppService,
    private _offers: OffersService,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
