import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[NumberOnly]',
    standalone: true
})

export class NumberOnlyDirective implements OnInit {
constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
) {
 }

  ngOnInit() {
    this._renderer.setAttribute(this._el.nativeElement, 'type', 'text');
    this._renderer.setAttribute(this._el.nativeElement, 'inputmode', 'numeric');
    this._renderer.setAttribute(this._el.nativeElement, 'pattern', '[0-9]*');
  }
}
