import { Injectable } from '@angular/core';
import {BasePromo, WelcomePromoModal} from '../base-promo';
import { combineLatest, Observable, of } from 'rxjs';
import { ITournament } from '../../tournaments/tournaments.interface';
import { GameCategory } from '../../../core/services/games/game-category';
import {filter, first, map, share, switchMap, tap} from 'rxjs/operators';
import {
  BonusStage, CLAIMED_WELCOME_4_SETUP,
  CLAIMED_WELCOME_BONUSES_GROUP, USER_HAVE_ONE_DEPOSIT_GROUP,
  USER_RECEIVED_PHONE_BONUS
} from '../../../core/services/user/data/user-bonuses.data';
import {UserPhoneService} from '../../../core/services/user/user-phone.service';
import {LanguageService} from '../../../core/services/language/language.service';
import {LAST_BONUS_ID, LAST_WELCOME_BONUS_SPINNED} from './halloween.component';
import {HalloweenPromoPage} from './halloween.interface';

export const PROMO_KEYS = {
  BONUS_WHEEL_FS: 'HALLOWEEN',
};

export let WHEEL_BONUSES: { prize: string, image: string }[] = [];

/**
 * Enum defining different actions related to Easter promotion
 */
export enum HalloweenAction {
  GO_TO_DEPOSIT = 'deposit',
  TOURNAMENTS = 'tournaments',
  PROMO_AUTH = 'promo-auth',
  GET_BONUS = 'get-bonus',
  GO_TO_BONUSES = 'bonuses',
  GO_TO_PROFILE = 'profile',
  HUNT = 'hunt'
}

@Injectable({
  providedIn: 'root'
})
export class HalloweenService extends BasePromo {


  /**
   * Detect end of day left time
   * @private
   */
  private _endOfDayLeft = this.time.toLocalDate(this.time.detectEndOfDay());

  /**
   * Configuration for different actions and their corresponding routes
   */
  private _actionRoutesConfig = {
    [HalloweenAction.GET_BONUS]: {
      key: null,
      url: '/account',
      authUrl: ['account'],
    },
    [HalloweenAction.GO_TO_BONUSES]: {
      key: null,
      url: '/bonuses',
      authUrl: ['bonuses'],
    },
    [HalloweenAction.TOURNAMENTS]: {
      key: 'promo-tournaments',
      url: '/tournaments',
      authUrl: ['tournaments'],
    },
    [HalloweenAction.GO_TO_DEPOSIT]: {
      key: 'promo-deposit',
      url: '/account/deposit',
      authUrl: ['/', this._lang.current, 'account', 'deposit'],
    },
    [HalloweenAction.PROMO_AUTH]: {
      key: 'before-login',
      url: '/promo/halloween',
      authUrl: null,
    },
    [HalloweenAction.GO_TO_PROFILE]: {
      key: null,
      url: '/account/profile',
      authUrl: null,
    },
    [HalloweenAction.HUNT]: {
      key: null,
      url: '/account',
      authUrl: null,
    },
  };

  public eventName = 'halloween';

  tournamentsBreakpoints = {
    '(max-width: 768px)': {
      slidesPerView: 2,
      spacing: 20
    },
    '(max-width: 536px)': {
      slidesPerView: 1.3,
      centered: true,
      spacing: 20,
      loop: true
    },
    '(max-width: 325px)': {
      slidesPerView:  1.2,
      centered: true,
      spacing: 20,
      loop: true
    },
    '(min-width: 769px)': {
      slidesPerView: 3,
      autoplay: false
    },
  };

  gamesBreakpoints = {
    '(max-width: 768px)': {
      slidesPerView: 5,
      spacing: 20
    },

    '(max-width: 767px)': {
      slidesPerView: this.window.nativeWindow ? this.window.nativeWindow.innerWidth / 145 : 5,
      spacing: 20
    },
  };

  /**
   * Current fs prize
   */
  public currentPrize: any;

  /**
   * Is promo loaded
   */
  public isLoadedPromo: boolean;


  /**
   * Current tournaments
   * @param _time
   */
  public tournaments$: Observable<ITournament[]> = this._getTournaments$();
  /**
   * Games list by category
   */
  public gameList$: Observable<any> = this.getGameListByCategory();

  public currentWelcomeBonus: { cash: any, fs: any, lootbox: any };

  /**
   * Getter time left end of day
   */
  get getTimeLeft(): Date {
    return this._endOfDayLeft;
  }

  /**
   * Lootbox promo
   */
  public lootboxPromoBonus: any;

  /**
   * User already activate today lootbox
   */
  public isUserAlreadyActivateBox: boolean;

  /**
   * User claimed all welcome bonuses
   */
  public isUserCanClaimAllBonuses: boolean;

  public isPhoneVerified$: Observable<boolean> = this.user.auth$.pipe(
    filter(auth => !!auth),
    switchMap(() => this._phone.getPhoneList()),
    map(() => {
      return !this._phone.verified && !this.group.isExistGroup(USER_RECEIVED_PHONE_BONUS) &&
        this.group.isExistGroup(USER_HAVE_ONE_DEPOSIT_GROUP) && this.bonuses.checkDifferenceMoreTwoDays()
    }),
    first(),
    share()
  );

  public lastWelcomeId;

  public wheelPrizeImg: string;

  public LAST_BONUS_ID = 'last-bonus';
  public LAST_WELCOME_BONUS_SPINNED = '--last-welcome-bonus-spinned';

  constructor(
    private _phone: UserPhoneService,
    private _lang: LanguageService
  ) {
    super();
  }

  get halloweenAction() {
    return HalloweenAction;
  }

  get isWelcomeBonusExist(): boolean {
    return Boolean(this.currentWelcomeBonus.fs) || Boolean(this.currentWelcomeBonus.cash) || Boolean(this.currentWelcomeBonus.lootbox);
  }

  public isAbleToSpinLootbox() {
    return Boolean(this.currentWelcomeBonus.lootbox) || this.isUserCanClaimAllBonuses && this.cookie.check(this.LAST_WELCOME_BONUS_SPINNED) || this.lootboxPromoBonus
  }

  /**
   * Get promo page
   */
  public getPromo$(): Observable<any> {
    this.isLoadedPromo = false;
    this.isUserCanClaimAllBonuses = this.group.isExistGroup(CLAIMED_WELCOME_BONUSES_GROUP)
      || this.group.isExistGroup(CLAIMED_WELCOME_4_SETUP);


    if (this.isUserCanClaimAllBonuses) {
      this.cookie.delete(LAST_BONUS_ID);
    }

    return this.user.auth$.pipe(
      switchMap(auth => this._fetchPromoData$(auth)),
      map(([promo, lootbox, fs, bonuses]) =>
        this._processPromoData$(promo, lootbox, fs, bonuses)),
      tap((list) => {
        console.log(list);
        this._updateUserBonusInfo(list);
        setTimeout(() => this.isLoadedPromo = true, 500);
      }),
    );
  }

  /**
   * Private method to update user bonus information based on the provided list.
   * @param list The list containing user bonus information.
   */
  private _updateUserBonusInfo(list: any): void {
    this.currentWelcomeBonus = {
      cash: this._getFilteredCashWelcomeBonuses(list?.bonuses),
      fs: this._getFilteredFreespinsWelcomeBonuses(list?.fs),
      lootbox: this._getFilteredWelcomeLootbox(list?.lootbox),
    };

    const lastBonus = +this.cookie.get(this.LAST_BONUS_ID);

    if (this._isNotAbleLastWelcomeSpin(lastBonus)) {
      this.currentWelcomeBonus = {
        cash: undefined,
        fs: undefined,
        lootbox: undefined,
      };
    }


    this.lootboxPromoBonus = this._getFilteredLootboxBonuses(list?.lootbox);
    this.isUserAlreadyActivateBox = this._checkActivatedTodayLootbox(list?.lootbox);

    WHEEL_BONUSES = list?.Prizes;
  }

  /**
   * Function to process fetched Easter promotion data
   * @param promo Array of EasterPageTypes
   * @param lootbox
   * @param fs Array of free spins
   * @param bonuses
   * @returns Processed Easter promotion data
   */
  private _processPromoData$(promo: HalloweenPromoPage[], lootbox: any[], fs: any[], bonuses: any[]): any {
    if (promo?.[0]?.statusCode) {
      return this.responseHandler.handleResponseStatus(promo?.[0]?.statusCode);
    }
    return promo?.map(promoItem => {
      return {
        ...promoItem,
        fs,
        lootbox,
        bonuses,
        Prizes: this._convertDataPrizes([...Object.values(promoItem?.Prizes)]),
        HowItWorks: [...Object.values(promoItem?.HowItWorks)],
        HuntHowItWorks: [...Object.values(promoItem?.HuntHowItWorks)],
        unpublishAt: this.time.toLocalDate(promoItem?.unpublishAt),
      }
    }).map(item => ({
      ...item,
    }))[0];
  }

  /**
   * Function to fetch Summer promotion data from API
   */
  private _fetchPromoData$(auth): Observable<[HalloweenPromoPage[], any[], any[], any[]]> {
    return combineLatest([
      this.page.item({ slug: this.eventName}),
      auth ? this.lootbox.loadUserLoobox(): of([]),
      auth ? this.bonuses.freeSpinsList() : of([]),
      auth ? this.bonuses.bonusList() : of([]),
    ]);
  }

  private _isNotAbleLastWelcomeSpin(lastBonus): boolean {
    return this.currentWelcomeBonus.fs?.id === lastBonus || this.currentWelcomeBonus.cash?.id === lastBonus
      || this.currentWelcomeBonus.lootbox?.id === lastBonus
      || (this.isUserCanClaimAllBonuses && this.cookie.check(this.LAST_WELCOME_BONUS_SPINNED))
  }

  private _getFilteredLootboxBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(lootbox => lootbox.lootboxTitle.trim().toLowerCase().includes('landing') &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredWelcomeLootbox(arrayToCheck: any[]) {
    return arrayToCheck?.filter(lootbox => lootbox.isWelcome &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredFreespinsWelcomeBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.ISSUED && bonus.isWelcome)
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _getFilteredCashWelcomeBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.HANDLE_BETS && bonus.isWelcome)
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _checkActivatedTodayLootbox(arrayTcCheck: any[]) {
    return arrayTcCheck?.some(l => l.stage === BonusStage.ACTIVATED && l.lootboxTitle.trim().toLowerCase().includes('landing') &&
      this.time.isTodayDate(new Date(l.created_at)));
  }

  /**
   * Get tournaments and filter by slug
   */
  private _getTournaments$(): Observable<any> {
    return this.tournaments.list().pipe(
      filter(tournaments => !!tournaments),
      map(tournaments => tournaments.filter(tournament => {
        return tournament.slug.includes('halloween');
      })),
    );
  }

  async openHuntModal() {
    const component = await import('../../../core/modal-v2/components/lazy/halloween-hunt-modal/halloween-hunt-modal.component');
    this.openedModal = await this.modals.openLazy(component?.HalloweenHuntModalComponent, {
      template: 'CLEAR',
      data: {
        size: this.eventList.length,
        data: this.huntModalData
      },
      closeAfterRouteChange: true
    });

    this.openedModal.onResult().pipe().subscribe(() => this.openedModal = undefined);
  }

  /**
   * Function to handle different actions related to Easter promotion
   * @param action The action to perform
   * @param bonusCode Optional bonus code
   * @param successUrl Optional success URL
   */
  public async onAction(
    action: HalloweenAction,
    bonusCode: string = '',
    successUrl: string = this.window.nativeWindow.location.href,
  ) {
    const routeConfig = this._actionRoutesConfig[action];
    const { key, url, authUrl } = routeConfig;

    switch (action) {
      case HalloweenAction.PROMO_AUTH:
      case HalloweenAction.TOURNAMENTS:
      case HalloweenAction.GO_TO_BONUSES:
      case HalloweenAction.GET_BONUS:
      case HalloweenAction.GO_TO_PROFILE:
      case HalloweenAction.HUNT:
        await this._handleActionRoute(key, url, authUrl);
        break;
      case HalloweenAction.GO_TO_DEPOSIT:
        await this._handleActionRoute(
          key,
          url,
          authUrl,
          { successUrl, bonusCode },
          { queryParams: { successUrl, bonusCode } },
        );
        break;
    }
  }

  /**
   * Function to handle routing for different actions
   * @param key The key for the action
   * @param url URL for non-authenticated users
   * @param authUrl URL for authenticated users
   * @param urlData Additional data for URL
   * @param extras Additional navigation options
   */
  private async _handleActionRoute(key: string, url: string, authUrl: string[], urlData?: any, extras?: any) {
    const urlParams = { key, url, authUrl, urlData, extras };
    if (!key) {
      await this.router.navigateByUrl(url);
      return;
    }
    if (!this.user.auth) {
      this.local.addUrl(urlParams.key, urlParams.url, urlParams.urlData);
      await this.user.authUser();
    } else {
      await this.router.navigate(urlParams.authUrl, urlParams.extras);
    }
  }

  /**
   * Get games list by category for bottom slider
   */
  public getGameListByCategory(): Observable<any> {
    return this.cmsApi.gameList({category: GameCategory.HALLOWEEN_ID})
      .pipe(
        map(list => [
          this.array.toChunks(this.games.gameListMapper(this.array.checkAndCloneArray(list.data?.gameList?.slice(0, 8), 2)), 4),
          this.games.gameListMapper(list.data.gameList)
        ]),
        map(list => {
          list[1] = list[1].length <= 5 ? [...list[1], ...list[1]] : list[1];
          return list;
        }),
      );
  }

  public checkActivatedBonuses(data: WelcomePromoModal): Observable<any> {
    return this.bonuses.halloweenActivatedBonusesFs$.pipe(
      map((activatedBonusesFs) => {
        return !Boolean(activatedBonusesFs.length);
      }),
      first(),
      tap(async (isShouldOpened) => {
        if (isShouldOpened) {
          const component = await import('../../../core/modal-v2/components/lazy/welcome-promo/welcome-promo.component');
          await this.modals.openLazy(component?.WelcomePromoComponent, {template: 'CLEAR', data});
          this.cookie.set('welcome-modal-promo', 'true', 1, '/');
        }
      }),
    );
  }

  private _convertDataPrizes(data: { prize: string, image: string }[]) {
    return data.map(item => {
      return {
        prize: item.prize === 'secret' ? item.prize : +item.prize,
      };
    });
  }

  public handleWelcomeSpin(isLootbox: boolean) {
    this.lastWelcomeId = !isLootbox
      ? this.currentWelcomeBonus.cash?.id || this.currentWelcomeBonus.fs?.id
      : this.currentWelcomeBonus.lootbox?.id;

    this.cookie.set(this.LAST_BONUS_ID, this.lastWelcomeId);

    if (this.isUserCanClaimAllBonuses) {
      this.cookie.set(this.LAST_WELCOME_BONUS_SPINNED, '1');
    }
  }

}
