import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {debounceTime} from 'rxjs/operators';
import {CommonModule} from '@angular/common';


import {CommonDataService} from '../../services/common-data.service';
import {ClickOutsideDirective} from '../../shared/directives/click-outside.directive';
import {TranslatePipe} from '../../shared/translation/translate.pipe';

@UntilDestroy()
@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideDirective,
    TranslatePipe
  ]
})
export class CountrySelectorComponent implements OnInit, OnDestroy {

  @Output() callingCode: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Is country list open
   */
  private _open: boolean;

  /**
   * Country list after filter
   */
  public filteredCountryList: any[] = this.data.countryList;

  /**
   * Search form controller
   */
  public searchForm: UntypedFormGroup = new UntypedFormGroup({
    query: new UntypedFormControl('')
  });

  @Input() countryName: boolean;

  /**
   * Country control
   */
  @Input() control: AbstractControl;

  /**
   * Arrow icon state
   */
  @Input() showArrow: boolean;

  constructor(
    public data: CommonDataService
  ) { }

  ngOnInit() {
    this._handleSearch();
  }

  ngOnDestroy(): void {
  }

  get isOpen(): boolean {
    return this._open;
  }

  /**
   * Open countries list
   */
  open() {
    this._open = true;
  }

  /**
   * Close countries list
   */
  close() {
    this._open = false;
  }

  /**
   * Select country
   *
   * @param country
   */
  select(country: any) {
    this.close();
    this.control.setValue(country.short);
    this.callingCode.emit(country.callingCode);
  }

  /**
   * Handle search form and filter countries
   *
   * @private
   */
  private _handleSearch() {
    this.searchForm.get('query').valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      debounceTime(150),
      tap(value => this.filteredCountryList = this.data.countryList.filter(country => country.name.toLowerCase().includes(value.toLowerCase())))
    ).subscribe();
  }

}

