import {
  AfterContentInit,
  ContentChildren,
  Directive,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { RouterLinkActive } from '@angular/router';

@Directive({
    selector: '[routerLink]',
    standalone: true
})
export class RouterLinkDirective implements OnChanges, AfterContentInit {
  @Input() routerLink: any[] | string;

  @ContentChildren(RouterLinkActive, { descendants: true })
  linkActives !: QueryList<RouterLinkActive>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routerLink && this.linkActives && this.linkActives.first) {
      this.linkActives.first.ngOnChanges(null);
    }
  }

  ngAfterContentInit() {
    this.linkActives.changes.subscribe(_ => this.linkActives.first.ngOnChanges(null));
  }
}
