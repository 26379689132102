import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import {filter, tap} from 'rxjs/operators';
import {StaticContentService} from '../static-content.service';

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionsService {

  /**
   * Holds current user subscriptions
   */
  public subscriptions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  /**
   * Subject that emits true, if user have to reseive promos
   */
  public receiveEmailPromosValue$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Subject that emits true, if user have to reseive promos
   */
  public receiveTermsAcceptanceValue$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private _api: SsApiService,
    private _static: StaticContentService
  ) { }

  /**
   * Returns list of user subscriptions
   */
  subscriptionsList(): Observable<any> {
    return this._api.subscriptions();
  }

  /**
   * Set user subscriptions
   *
   * @param subscriptions
   */
  setSubscriptions(subscriptions: object): Observable<any> {
    return this._api.putSubscriptions({
      subscription_params: subscriptions
    });
  }

  /**
   * Update subscriptions in service
   */
  updateSubscriptions() {
    this.subscriptionsList().subscribe(subs => {
      this.subscriptions$.next(subs);
    });
  }


  /**
   * Resolves the value of the "receive_promos" form control based on the user's geo location.
   * If user get value from cms, then the value of the "receive_promos" form control will be set to true.
   */
  public resolveReceiveSmsPromosByGeo() {
    this._static.item({ slug: 'active-receive-promo-checkbox-by-geo' }).pipe(
      filter(item => item?.length),
      tap(() => this.receiveEmailPromosValue$.next(true)),
    ).subscribe();
  }

  /**
   * Resolves the value of the "terms_acceptance" form control based on the user's geo location.
   * If user get value from cms, then the value of the "terms_acceptance" form control will be set to true.
   */
  public resolveTermsAcceptanceByGeo() {
    this._static.item({ slug: 'active-terms-acceptance-checkbox-by-geo' }).pipe(
      filter(item => item?.length),
      tap(() => this.receiveTermsAcceptanceValue$.next(true)),
    ).subscribe();
  }
}
