import { Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { TimeService } from '../services/time.service';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';

@UntilDestroy()
@Component({
    template: '',
    standalone: true
})
export class BaseTimerComponent implements OnDestroy {

  @Input() timeLeft: Date;

  public timeLive: any;

  public time: TimeService = inject(TimeService);

  @Output() timeLeftWhenDestroy: EventEmitter<any> = new EventEmitter<any>();
  @Output() timeFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  public resolveTimer() {
    if (this.timeLeft) {
      this.time.currentDate$()
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.timeLive = this.time.timeDiff(typeof this.timeLeft === 'string' ? this.time.toLocalDate(this.timeLeft) : this.timeLeft);
          if (!this.timeLive.days && !this.timeLive.hours && !this.timeLive.minutes && !this.timeLive.seconds) {
            this.timeFinished.emit(true);
          }
        });
    } else {
      this.timeLive = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        daysLabel: 't.days',
        hoursLabel: 't.hrs',
        minLabel: 't.mins',
        secLabel: 't.secs'
      };
    }
  }

  ngOnDestroy() {
    this.timeLeftWhenDestroy.emit(this.timeLeft);
  }
}
