import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ignoreHtmlTags',
    standalone: true
})
export class IgnoreHtmlTagsPipe implements PipeTransform {

  constructor() {}

  public transform(value: string): string {
    return value?.replace(/<([^^]*?)>/gi, '');
  }
}
