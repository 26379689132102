import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import { SideNavComponent } from '../../../../shared/layout/side-nav/side-nav.component';
import { RegisterFormComponent } from '../../../../modules/register-form/register-form.component';
import { HeaderComponent } from '../../../../shared/layout/header/header.component';

@Component({
    selector: 'app-sign-up-modal',
    templateUrl: './sign-up-modal.component.html',
    styleUrls: ['./sign-up-modal.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RegisterFormComponent, SideNavComponent]
})
export class SignUpModalComponent implements OnInit, ModalContent {

  public modal: ModalRef;

  constructor() { }

  ngOnInit() {
  }

}
