import {Inject, Injectable} from '@angular/core';
import {PlatformService} from './platform.service';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from 'ngx-unificator/tokens';

declare var dataLayer: any[];

enum ACTIONS {
  LOGIN_ACTION = 'login',
  REGISTER_ACTION = 'register',
  CLICK_ACTION = 'button_click',
}

enum CATEGORIES {
  FORM_SUBMIT = 'form_submit',
  DEPOSIT_START = 'deposit_start',
  LIMIT = 'limit',
  ABTEST = 'ab-test'
}

enum TRANSACTIONS {
  DEPOSIT = 'deposit',
}

enum GTAG_COOKIES {
  FIRST_DEPOSIT_SENT = 'gtm-first-deposit-sent',
  USER_IDS = 'gtm-user-ids'
}

const TIMESTAMP = +new Date();

export interface GoogleTagManagerConfig {
  id: string | null;
  gtm_auth?: string;
  gtm_preview?: string;

  [key: string]: string | null | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  /**
   * List of already focused fields
   */
  private _firstFocusEventList = new Map<any, any>();

  private _isLoaded = false;
  public config: GoogleTagManagerConfig = { id: 'GTM-T2FB6TL' };

  constructor(
    private _platform: PlatformService,
    @Inject(WINDOW) private _window: Window,
    @Inject(DOCUMENT) private _document: Document,
  ) {
  }

  public getDataLayer(): any[] {
    const window = this._window;
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }

  public addGtmToDom() {
    return new Promise((resolve, reject) => {
      if (this._isLoaded) {
        return resolve(this._isLoaded);
      }
      const doc: any = this._document;
      this._pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        'event': 'gtm.js',
      });
      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this._applyGtmQueryParams(
        this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js',
      );
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
      if (this._platform.isBrowser) {
        gtmScript.addEventListener('load', () => {
          return resolve((this._isLoaded = true));
        });
        gtmScript.addEventListener('error', () => {
          return reject(false);
        });
      } else {
        return resolve((this._isLoaded = true));
      }
    });
  }

  private _pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private _applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter(k => this.config[k])
        .map(k => `${k}=${this.config[k]}`)
        .join('&')
    );
  }
  /**
   * signUp Success
   * @userId
   */
  public signUpSuccess(userId) {
    this._pushOnDataLayer({
      event: 'create_account_button',
      event_action:  ACTIONS.CLICK_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: 'create_account',
      dimension1: userId
    });
  }

  /**
   * Register form inputs focus
   * @param {string} eventName
   * @param {string} label
   */
  public registerFormInputFocus(eventName: string, label: string) {
    const eventVal = {
      event: eventName,
      eventAction: 'focus',
      eventCategory: 'register',
      eventLabel: label
    };
    const eventKey = Object.values(eventVal).join();
    if (!this._firstFocusEventList.has(eventKey)) {
      this._firstFocusEventList.set(eventKey, true);
      this._pushOnDataLayer(eventVal);
    }
  }

  /**
   * Sign in success
   * @userId
   */
  public signInSuccess(userId) {
    this._pushOnDataLayer({
      event: 'login_account_button',
      event_action: ACTIONS.CLICK_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: 'login_account',
      dimension1: userId
    });
  }

  /**
   * Register error event
   * @param {string} eventName
   * @param error
   */
  public registerError(eventName: string, error) {
    this._pushOnDataLayer({
      event: eventName,
      event_action: 'register_error',
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: error,
    });
  }

  /**
   * Update profile event
   * @param eventName
   * @param label
   */
  public updateProfileModalSubmitClick(eventName: string, label: string) {
    this._pushOnDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Login button on game page click
   * @param {string} eventName
   * @param {string} label
   */
  public setAbTest(eventName: string, action: string, label: string) {
    if (label) {
      this._pushOnDataLayer({
        event: eventName,
        event_action: action,
        event_category: CATEGORIES.ABTEST,
        event_label: label
      });
    }
  }

  /**
   * signUp Success Timestamp
   * @userId
   */
  public signUpSuccessTimestamp(eventName: string, label: string, userId: number) {
    this._pushOnDataLayer({
      event: eventName,
      event_action: TIMESTAMP,
      event_category: 'timestamp',
      event_label: label,
      dimension1: userId
    });
  }


  /**
   * Game click event
   * @param gameid
   */
  public gameClickEvent(gameid: string) {
    this._pushOnDataLayer({
      event: 'game_click',
      gameid,
    });
  }

  /**
   * Banner Click Event
   * @param bannerOrder
   */
  public bannerClickEvent(bannerOrder: string) {
    this._pushOnDataLayer({
      event: 'click_on_main_banner',
      eventCategory: 'main_banner',
      eventLabel: 'event_label',
      eventValue: bannerOrder,
    });
  }

  /**
   * Add custom event
   * @param event
   */
  public addEvent(event: string) {
    this._pushOnDataLayer({event});
  }
}
