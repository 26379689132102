import { Injectable } from '@angular/core';
import { SsApiService } from './api/ss-api.service';
import { Observable } from 'rxjs';
import { UserService } from './user/user.service';
import { filter, tap } from 'rxjs/operators';
import {LoyaltyStatusesID, UserLoyaltyStatusesId} from '../helpers/loyalty-statuses';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  private _loyaltyGroup: LoyaltyStatusesID = null;

  constructor(
    private _api: SsApiService,
    private _user: UserService
  ) {
    this._setLogicForStreams();
  }

  get loyaltyGroup(): LoyaltyStatusesID {
    return this._loyaltyGroup;
  }

  /**
   * Set logic for streams
   */
  private _setLogicForStreams() {
    this._user.auth$.pipe(
      filter(auth => auth),
      tap(() => this.defineCurrentLoyaltyGroup())
    ).subscribe();
  }

  /**
   * Resolve current player loyalty group
   */
  defineCurrentLoyaltyGroup(): void {
    if (!this._user.auth || !this._user.info.statuses.length) {
      return null;
    }

    const statusGroup = UserLoyaltyStatusesId.reverse().find(id => this._user.info.statuses.some(s => s.id === id));

    this._loyaltyGroup = statusGroup || null;
  }

  /**
   * Add user to provided group
   *
   * @param group
   */
  public addToGroup(group: string | number): Observable<any> {
    return this._api.postPlayerGroups({
      groups: {
        add: [group]
      }
    });
  }

  /**
   * Remove user from provided group
   *
   * @param group
   */
  public removeFromGroup(group: string | number): Observable<any> {
    return this._api.postPlayerGroups({
      groups: {
        remove: [group]
      }
    });
  }

  /*
   * Is exist group in user`s groups
   *
   * @param group
   */
  public isExistGroup(groupId: number | string): boolean {
    if (!this._user.auth || !this._user.info.statuses.length) {
      return null;
    }

    return Boolean(this._user.info.statuses.find(status => status.id === groupId));
  }
}
