import {Injectable} from '@angular/core';

export enum CurrencyExchange {
  'USD' = 1.18,
  'NOK' = 10.73,
  'PLN' = 4.4,
  'CAD' = 1.58,
  'ZAR' = 20.1,
  'NZD' = 1.78,
  'HUF' = 344.95,
  'JPY' = 124.31,
}

export type Currency = 'EUR' | 'USD' | 'NOK' | 'PLN' | 'CAD' | 'ZAR' | 'NZD' | 'HUF' | 'JPY';

@Injectable({
  providedIn: 'root'
})
export class CurrencyConverterService {

  /**
   * Required to get amount from cents
   */
  private _centDividersList: any = {
    'EUR': 100, // Required default param
  };

  constructor() {

  }

  /**
   * Convert cents to amount
   *
   * @param amount
   * @param currency
   */
  public centsToAmount(amount: number, currency: string): number {
    if (this._centDividersList.hasOwnProperty(currency)) {
      return amount / this._centDividersList[currency];
    } else {
      return amount / this._centDividersList['EUR']; // if in array not exists required currency - divide to EUR
    }
  }

  /**
   * Convert EUR to another currency (use for course where accuracy is not very important)
   * 10 eur => 11.8 usd
   * @param currency
   * @param value
   */
  public convertEurToAnotherCurrency(value: number, currency: Currency): number {
    return value * (CurrencyExchange[currency] || 1);
  }

}
