import { Injectable } from '@angular/core';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import {ReplaySubject} from 'rxjs';
import { UpdateOnLangChange } from '../../core/shared/decorators/update-on-lang-change';
import { CmsContentMapperService } from '../../core/services/cms-content-mapper.service';
import {map} from 'rxjs/operators';
import {TimeService} from "../../core/services/time.service";
import {UserService} from "../../core/services/user/user.service";
import {GroupsService} from "../../core/services/groups.service";

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(
    private _api: CmsApiService,
    private _mapper: CmsContentMapperService,
    private _time: TimeService,
    private _user: UserService,
    private _groups: GroupsService
  ) { }

  /**
   * Get promotions list from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('promo/list')
  list(params: object = {}): ReplaySubject<any> {
    return this._api.promotionList(params).pipe(
      map(response => this._mapper.mapCmsData(response.data, {
        slug: 'slug'
      }))
    ) as ReplaySubject<any>;
  }

  /**
   * Get promotions item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('promo/item')
  item(params: object = {}): ReplaySubject<any> {
    return this._api.promotionItem(params).pipe(
      map(response => {
        if (response.statusCode === 200) {
          return this._mapper.mapCmsData(response.data, {
            slug: 'slug',
            publishAt: 'publishAt.date',
            unpublishAt: 'unpublishAt.date',
            name: 'name'
          });
        } else {
          return [{
            statusCode: response.statusCode
          }];
        }
      })
    ) as ReplaySubject<any>;
  }
}
