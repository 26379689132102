import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, share, tap } from 'rxjs/operators';
import { LanguageService } from './language/language.service';
import { environment } from 'src/environments/environment';
import { PlatformService } from './platform.service';
import { devDomain } from 'src/config/dev.domain';


type LocationType = 'country' | 'state' | 'city' | 'postcode' | 'street' | 'amenity' | 'locality';

/**
 * https://apidocs.geoapify.com/docs/geocoding/address-autocomplete/#autocomplete
 */
@Injectable({
  providedIn: 'root',
})
export class GeoApifyService {
  /**
   * Api Key - uses for new version of API
   */
  private readonly API_KEY = '7e3d7041db834ad7a2ffcd51d0a4fd98';
  private readonly errorHost = environment.error_host + '/error/handle';

  private _ignoredHosts = [
    'https://localhost:4200',
    'https://stage.wildfortune.io',
    'https://dev.wildfortune.io',
    'https://wildfortune.io',
    ...devDomain
  ];

  constructor(
    private _http: HttpClient,
    private _lang: LanguageService,
    private _platform: PlatformService
  ) { }

  /**
   * Get location predictions by provided params
   * @param options List of available options: https://developer.here.com/documentation/geocoder-autocomplete/topics/resource-suggest.html
   */
  public getPredictions(options: { type?: LocationType; text?: string; name?: string; filter?: any }): Observable<any> {
    return this._http
      .get(`https://api.geoapify.com/v1/geocode/autocomplete`, {
        params: {
          apiKey: this.API_KEY,
          format: 'json',
          limit: 10,
          lang: this._lang?.current?.match(/^[^-]+/)[0] || 'en',
          ...options,
        },
      })
      .pipe(
        share(),
        map((response: any) => response.results || []),
        tap(() => {
          this._sendCustomData();
        })
      );
  }

  /**
   * Returns list of cities from list of all suggestions
   * @param suggestionList
   * @param maxCount
   */
  public filterCities(suggestionList: Array<any>, maxCount: number = 3): Array<any> {
    let count = 0;
    if (suggestionList) {
      const uniqueCities = new Set();
      const filteredItems = suggestionList.filter(item => {
        if (item.city) {
          const duplicate = uniqueCities.has(item.city);
          uniqueCities.add(item.city);
          return !duplicate;
        }
      });

      return filteredItems.filter(suggestion => {
        if (count < maxCount) {
          count++;
          return true;
        }
      });
    } else {
      return [];
    }
  }

  /**
   * Returns list of streets from list of all suggestions
   * @param suggestionList
   * @param city
   * @param maxCount
   */
  public filterAddress(suggestionList: Array<any>, city: string = null, maxCount: number = 3): Array<any> {
    let count = 0;

    return !suggestionList
      ? []
      : suggestionList.filter(suggestion => {
        if (count < maxCount) {
          if (city) {
            if (suggestion.city === city) {
              count++;
              return true;
            }
          } else {
            count++;
            return true;
          }
        }
      });
  }

  /**
   * Send custom data
   */
  private _sendCustomData() {
    if (!this._platform.isBrowser) {
      return;
    }
    if (!navigator.onLine) {
      return;
    }
    try {
      this._http.post(this._resolveHost(), {
        'ts': new Date().toLocaleString(),
        'error': 'place-api-counter',
        'key': 'place-api-counter',
        'type': 'place-api-counter-type',
        'userAgent': {
            'userAgent': this._getUserAgent()
        },
        'channel': 'place-api-counter',
        'isLocalizationLoaded': false,
      }, {withCredentials: true}).subscribe();
    } catch (e) {}
  }

  /**
   * Resolve host for mirrors
   * @private
   */
  private _resolveHost() {
    if (this._platform.isBrowser) {
      const host = window.location.host;
      const hostName = host.split('.')[1];
      return !this._ignoredHosts.some(item => item.includes(host)) ?
        this.errorHost.replace('wildfortune', hostName) :
        this.errorHost;
    } else {
      return this.errorHost;
    }
  }

  /**
   * Returns object containing information about user-agent and internet connection of user
   * @private
   */
  private _getUserAgent() {
    const nav: any = navigator;
    return {
      location: window.location.href,
      language: nav.language || 'unknown',
      platform: nav.platform || 'unknown',
      userAgent: nav.userAgent || 'unknown',
      connectionDownlink: nav.connection ? nav.connection.downlink : 'unknown',
      connectionEffectiveType: nav.connection ? nav.connection.effectiveType : 'unknown',
    };
  }

}
