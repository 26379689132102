<div class="notifications" [ngClass]="{'open': notification.dropdownOpen}" (scroll)="$event.preventDefault()">
  <div class="notifications__top">
    <div class="notifications__title">{{'t.notifications' | translate}}</div>
    <div class="notifications__top--close" (click)="notification?.close()"><i class="icon-close"></i></div>
  </div>
  <div class="notifications__list">
    @if (lists && lists.length) {
      @for (notify of lists; track notify) {
        <app-notification-center-item [notify]="notify"></app-notification-center-item>
      }
    } @else {
      <div class="notifications__empty">{{ 't.while-notification-empty' | translate }}</div>
    }
    <ng-template #empty>
      <div class="notifications__empty">{{ 't.while-notification-empty' | translate }}</div>
    </ng-template>
    <div class="notifications__bottom" (click)="notification?.close()">
      <div class="notifications__bottom--line"></div>
      <div class="notifications__bottom--close"><i class="icon-close"></i></div>
    </div>
  </div>
</div>
