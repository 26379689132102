import { environment } from 'src/environments/environment';
import { RedirectRoutes, Arguments } from './ss-payment-types';
import {devDomain} from '../../../../config/dev.domain';

export const SS_LIB_CONFIG: Arguments.ConfigArgs = {
  serverUrl: environment.ss_host,
  cdnList: ['https://cdn2.softswiss.net'],
};

export const redirectRoutes = (action: any): RedirectRoutes => {
  return {
    successUrl: (location?.origin as string) + `/payment-status/${action}/success`,
    failureUrl: (location?.origin as string) + `/payment-status/${action}/failure`,
    pendingUrl: (location?.origin as string) + '/profile/history',
    supportUrl: (location?.origin as string) + '/faq',
  };
};

export const CUSTOM_ROUTES: RedirectRoutes = {};

/**
 * List of app hosts from which request urls should not be modified
 */
export const ignoredHosts = [
  'https://localhost:4200',
  'https://stage.wildfortune.io',
  'https://dev.wildfortune.io',
  'https://stage.wildfortune.io',
  'https://wildfortune.io',
  ...devDomain
];

export const BONUS_CODE_QUERY_PARAM_NAME = 'bonusCode';
export const SUCCESS_QUERY_PARAM_NAME = 'successUrl';

/**
 * Methods which have own image on our side (USE id FROM REQUEST)
 */
export const methodsWithOwnImage = ['coinspaid_coinspaid-35_USDT_coinspaid', 'finteqhub_seamless_finteqhub_seamless-noda~noda-98_noda~noda'];

/**
 * Methods whith bigger image (USE id FROM REQUEST)
 */
export const methodsWithBiggerImage = ['devcode_devcode-mifinity-32_mifinity', 'finteqhub_seamless_finteqhub_seamless-noda~noda-98_noda~noda'];
