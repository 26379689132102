import {Component, ContentChildren, ElementRef, Input, OnDestroy, OnInit, QueryList} from '@angular/core';
import { SelectService } from '../select.service';
import { SelectOptionComponent } from '../select-option/select-option.component';
import { PlatformService } from '../../../services/platform.service';

@Component({
    selector: 'app-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.scss'],
    standalone: true
})
export class SelectDropdownComponent implements OnInit, OnDestroy {
  /**
   * Set width for select dropdown container
   */
  @Input() width: string;

  @ContentChildren(SelectOptionComponent) options: QueryList<SelectOptionComponent>;

  private _parent: HTMLElement;
  private _removed: boolean;

  constructor(
    public select: SelectService,
    public el: ElementRef,
    private _platform: PlatformService
  ) { }

  ngOnInit() {
    if (this._platform.isBrowser) {
      this._parent = this.el.nativeElement.parentElement;
      this.el.nativeElement.style.position = 'absolute';
      document.body.appendChild(this.el.nativeElement);
      this.update();
    }
  }

  update() {
    if (this.el.nativeElement.classList.contains('open')) {
      const rect = this._parent.getBoundingClientRect();
      if (
        document.documentElement.classList.contains('scrollblock') ||
        document.documentElement.classList.contains('no-scroll') ||
        document.documentElement.classList.contains('no-scroll-ios')
      ) {
        this.el.nativeElement.style.position = 'fixed';
      }
      this.el.nativeElement.style.width = this.width || rect.width + 'px';
      this.el.nativeElement.style.top = window.scrollY + (rect.top + rect.height / 2) + 'px';
      this.el.nativeElement.style.left = rect.left + 'px';
    }

    requestAnimationFrame(() => {
      if (!this._removed) this.update();
    });
  }

  ngOnDestroy(): void {
    this._removed = true;
    if (this._platform.isBrowser) {
      this.el.nativeElement.parentElement.removeChild(this.el.nativeElement);
    }
  }

}
