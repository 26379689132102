<div class="phone__wrapper">
  @if (userInfo.isFR) {
    <div class="phone__hint">{{ 't.phone-without-country-code' | translate }}</div>
  }
  <div class="phone" [ngClass]="{center: !showLabel, 'without-country': userInfo.isFR}">
    @if (showLabel) {
      <div class="phone__label">{{ label || ('t.add-verified-phone' | translate) }}</div>
    }
    @if (!userInfo.isFR) {
      <div (click)="onOpen()" class="country">
        <img class="country__img" [src]="'/assets/svg/flags/' + currentCountry + '.svg'" alt="">
        <i class="arrow down"></i>
      </div>
      <div class="countries" [ngClass]="{open: isOpen}" ClickOutside (clickOutside)="onClose()">
        <input class="countries__search" [formControl]="search" type="text" [placeholder]="'t.search' | translate">
        @if (filteredCountryList$ | async; as filteredCountryList) {
          <div class="countries__list">
            @for (country of filteredCountryList; track country) {
              <div
                (click)="onSelect(country)"
                [ngClass]="{'active': currentCountry === country.short}"
                class="countries__list--item"
                >
                <img [src]="'/assets/svg/flags/' + country?.short + '.svg'" alt="">
                <span>{{ country?.name }}</span>
              </div>
            }
          </div>
        }
      </div>
    }
    <input
      [prefix]="userPhone?.prefix"
      [mask]="userPhone.mask?.mask"
      [showMaskTyped]="true"
      [dropSpecialCharacters]="true"
      [validation]="false"
      [(ngModel)]="value"
      #inputModel="ngModel"
      type="text"
      class="input input--simple"
      >
    </div>

  </div>
