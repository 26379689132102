import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user/user.service';
import { map } from 'rxjs/operators';
import { CommonDataService } from '../../../../services/common-data.service';
import { WebSocketService } from '../../../../services/web-socket.service';
import { TranslatePipe } from '../../../translation/translate.pipe';
import { CurrencyFormatPipe } from '../../../pipes/currency-format.pipe';
import { RouterLinkDirective } from '../../../directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-header-authorized',
    templateUrl: './header-authorized.component.html',
    styleUrls: ['./header-authorized.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkDirective, AsyncPipe, CurrencyFormatPipe, TranslatePipe]
})
export class HeaderAuthorizedComponent implements OnInit {

  public balance$ = this._ws.userBalance$.pipe(
    map(balance => {
      return {
        ...balance,
        amount: this._commonData.subunitsToUnits(balance.amount_cents, balance.currency),
      };
    }),
  );

  constructor(
    public user: UserService,
    private _commonData: CommonDataService,
    private _ws: WebSocketService
  ) { }

  ngOnInit() {
  }
}
