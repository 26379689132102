<app-header [colorDuringSroll]="'#41307E'"></app-header>
<app-side-nav></app-side-nav>

@if (promo$ | async; as promo) {
  <div class="promo" [ngStyle]="{opacity: halloween.isLoadedPromo ? '1' : '0'}">

    @if ((halloween.lootboxPromoBonus || halloween.isWelcomeBonusExist) && !halloween.currentPrize) {
      @if (!halloween.isUserAlreadyActivateBox) {
        <div class="container">
          <div class="promo__header spin">
            <div class="promo__header_left spin">
              <app-halloween-timer [timeLeft]="promo?.unpublishAt"></app-halloween-timer>
              <div class="promo__header_spin" [innerHTML]="'spin <br> the wheel' | translate | safe: 'html'"></div>
              @if (isMobile()) {
                <div class="promo__header_right promo__header_right--wheel">
                  <app-halloween-wheel class="mobile" #wheelComponent></app-halloween-wheel>
                </div>
              }

              <button (click)="onSpinWheel()" class="btn big btn--primary blood">
                {{ 't.spin-now'  | translate }}
              </button>
            </div>

            @if (!isMobile()) {
              <div class="promo__header_right promo__header_right--wheel">
                <app-halloween-wheel #wheelComponent></app-halloween-wheel>
              </div>
            }
          </div>
        </div>
      }
    }

    @if (halloween.currentPrize) {
      <div class="promo__current-prize" [@showHide]>
        <div class="promo__current-prize_title" [innerHTML]="(isWelcomePrize ? promo?.WinSecretTitle : promo?.WinTitle) | safe: 'html'"></div>
        <div class="promo__current-prize_info">
          <img class="promo__current-prize_info--image" [src]="isMobile() ? '/assets/img/promo/halloween/prizes/default-mobile.png' : '/assets/img/promo/halloween/prizes/default.png'" alt="Promo prize - wildfortune casino">
          @if (halloween.currentPrize?.cash || halloween.currentWelcomeBonus?.cash) {
            <div class="promo__current-prize_info--prize cash" [innerHTML]="(halloween.currentPrize?.cash || halloween.currentWelcomeBonus?.cash) | safe: 'html'"></div>
            @if (halloween.currentPrize?.fs_count) {
              <div class="promo__current-prize_info--prize fs">+</div>
              <div class="promo__current-prize_info--prize fs">{{ halloween.currentPrize?.fs_count }} {{ 't.free-spins' | translate }}</div>
            }
          } @else {
            @if (halloween.currentPrize?.fs_count) {
              <div class="promo__current-prize_info--prize">{{ halloween.currentPrize?.fs_count }} <br> <span>{{ 't.free-spins' | translate }}</span></div>
            }
          }
        </div>
        <div class="promo__current-prize--desc">{{ 't.visit-account' | translate }}!</div>
        <div class="promo__current-prize--action">
          <button (click)="halloween.onAction(halloween.halloweenAction.GET_BONUS)" class="btn btn--primary big blood">
            {{ 't.get-bonus' | translate }}
          </button>
        </div>
      </div>
    }

    @if (!user.auth || (!halloween.isUserAlreadyActivateBox && !halloween.lootboxPromoBonus && !halloween.currentPrize && !halloween.isWelcomeBonusExist)) {
      <div class="promo__header">
          <div class="promo__header_left">
            <app-halloween-timer [timeLeft]="promo?.unpublishAt"></app-halloween-timer>
            <h1 class="promo__header_title" [innerHTML]="promo?.Title | safe: 'html'"></h1>
            <div class="promo__header_reward" [innerHTML]="'t.daily-mystery-reward' | translate | safe: 'html'"></div>
            <div class="promo__header_reward-prize" [innerHTML]="promo?.RewardPrize | safe: 'html'"></div>
            <div class="promo__header_right mobile">
              <div class="promo__header_lottie">
                <app-lottie
                  class="mobile"
                  [loop]="true"
                  [animationSpeed]="0.7"
                  [width]="isMobile() ? '70%' : '90%'"
                  [path]="'/assets/lottie/jsons/halloween/wheel.json'"
                  [assetsPath]="'/assets/lottie/images/halloween/wheel/'">
                </app-lottie>
                <img src="/assets/img/promo/halloween/prizes/default-mobile.png" alt="">
              </div>
            </div>
            <div class="promo__header_reward-title" [innerHTML]="promo?.RewardTitle | safe: 'html'"></div>
            <div class="promo__header_deposit-info" [innerHTML]="promo?.Rule | safe: 'html'"></div>
            @if (!user.auth) {
              <button (click)="halloween.onAction(halloween.halloweenAction.PROMO_AUTH)" class="btn big btn--primary blood">
                {{ user.authKeyTranslate  | translate }}
              </button>
            } @else {
              <button (click)="halloween.onAction(halloween.halloweenAction.GO_TO_DEPOSIT)" class="btn big btn--primary blood">
                {{ 't.deposit-and-spin'  | translate }}
              </button>
            }
          </div>
          <div class="promo__header_right desktop">
            <div class="promo__header_lottie">
              <app-lottie
                [loop]="true"
                [animationSpeed]="0.7"
                [width]="breakpoint.belowSmallDesktop ? '370px' : '480px'"
                [path]="'/assets/lottie/jsons/halloween/wheel.json'"
                [assetsPath]="'/assets/lottie/images/halloween/wheel/'">
              </app-lottie>
              <img src="/assets/img/promo/halloween/prizes/default-mobile.png" alt="">
            </div>
          </div>
        </div>
    }

    @if (user.auth && halloween.isUserAlreadyActivateBox && !halloween.currentPrize) {
      <div class="promo__header">
        <div class="promo__header_left with-bg">
          <h1 class="promo__header_title" [innerHTML]="promo?.Title | safe: 'html'"></h1>
          <div class="promo__header_reward disable" [innerHTML]="'t.daily-mystery-reward' | translate | safe: 'html'"></div>
          <div class="promo__header_right promo__header_right--with-shadow mobile">
            <div class="promo__header_lottie with-bg mobile">
              <app-lottie
                [loop]="true"
                [animationSpeed]="0.7"
                [width]="isMobile() ? '80%' : '90%'"
                [path]="'/assets/lottie/jsons/halloween/wheel.json'"
                [assetsPath]="'/assets/lottie/images/halloween/wheel/'">
              </app-lottie>
              <img src="/assets/img/promo/halloween/prizes/default-mobile.png" alt="">
            </div>
          </div>
          <div class="promo__header_try-tomorrow" [innerHTML]="promo?.TryTomorrowTitle | safe: 'html'"></div>
          <app-halloween-timer class="day" [timeLeft]="halloween.getTimeLeft" [isTryTomorrow]="true"></app-halloween-timer>
          <button (click)="halloween.onAction(halloween.halloweenAction.GO_TO_BONUSES)" class="btn big btn--primary blood">
            {{ 't.other-promotions'  | translate }}
          </button>
          <img src="/assets/img/promo/halloween/shadow.svg" alt="" class="shadow mobile">
        </div>
        <div class="promo__header_right desktop">
          <div class="promo__header_lottie">
            <app-lottie
              [loop]="true"
              [animationSpeed]="0.7"
              [width]="breakpoint.belowSmallDesktop ? '370px' : '480px'"
              [path]="'/assets/lottie/jsons/halloween/wheel.json'"
              [assetsPath]="'/assets/lottie/images/halloween/wheel/'">
            </app-lottie>
            <img src="/assets/img/promo/halloween/prizes/default-mobile.png" alt="">
          </div>
        </div>
      </div>
    }

    <div class="steps-container">
      <div class="container">
        <div class="title-main title-main--steps" [innerHTML]="promo?.HowItWorksTitle | safe: 'html'"></div>
        <div class="steps-container__steps">
          @for (step of promo?.HowItWorks; track step) {
            <div class="steps-container__steps--item">
              <span>{{ $index + 1 }}</span>
              <div [innerHTML]="step?.title | safe: 'html'"></div>
            </div>
          }
        </div>
        @if (!user.auth) {
          <button (click)="halloween.onAction(halloween.halloweenAction.PROMO_AUTH)" class="btn big btn--primary blood">
            {{ user.authKeyTranslate | translate }}
          </button>
        }
        @if (user.auth) {
          <button (click)="halloween.onAction(halloween.halloweenAction.GO_TO_DEPOSIT)" class="btn big btn--primary blood">
            {{ 't.deposit-and-spin'  | translate }}
          </button>
        }
      </div>
    </div>
    @if (halloween?.gameList$ | async; as gamesList) {
      @if (gamesList && gamesList.length) {
        <div class="container container-games">
          <div class="bottom-container__inner">
            <div class="bottom-container__left">
              <div class="title-main title-main--games">{{'t.games' | translate}}</div>
              <div class="games-desc" [innerHTML]="promo?.GamesDescription | safe: 'html'"></div>
              <div class="games-container games-container--desktop">
                <div class="slider__wrapper">
              <app-slider #gamesListSlider [config]="{spacing: 10, autoplay: false, slidesPerView: 5, centered:
                true, breakpoints: halloween.gamesBreakpoints}" [slideList]="gamesList[1]">
                    @for (game of gamesList[1]; track game; let i = $index) {
                      <div class="game-grid__item">
                        <app-game-preview [game]="game"></app-game-preview>
                      </div>
                    }
                  </app-slider>
                </div>
              </div>
              <div class="games-container games-container--mobile">
                <div class="container">
                  <div class="slider__wrapper">
                    <app-slider #gamesListSliderMobile [config]="{spacing: 10, slidesPerView: 2}" [slideList]="gamesList[0]">
                      @for (list of gamesList[0]; track list) {
                        <div class="game-grid">
                          @for (game of list; track game; let i = $index) {
                            <div class="game-grid__item">
                              <app-game-preview [game]="game"></app-game-preview>
                            </div>
                          }
                        </div>
                      }
                    </app-slider>
                    <div class="pagination-container">
                      @if (gamesListSlider) {
                        <ng-container *ngTemplateOutlet="gamesListSliderMobile?.paginationElement"></ng-container>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-btn-mobile">
                <button
                  (click)="!user.auth ? user.authUser() : null"
                  [routerLink]="['/', 'games', 'halloween-games']"
                  class="btn big btn--primary blood">
                  {{user.auth ? ('t.i-want-more' | translate) : ((env.isRestrictSignUp ? 't.sign-in' : user.authKeyTranslate) | translate)}}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    }

    @if (halloween.eventEnable) {
      <div class="container">
        <div class="hunt">
          @if (!halloween.isUserCatchHuntGroup) {
            <p class="hunt__title title-main" [innerHTML]="promo?.HuntTitle | safe: 'html'"></p>
            <img class="hunt__img" src="/assets/img/promo/halloween/hunt.png" alt="Halloween hunt - wildfortune casino">
            <div class="hunt__desc" [innerHTML]="promo?.HuntDesc | safe: 'html'"></div>
            <div class="hunt__how-works" [innerHTML]="promo?.HowItWorksTitle | safe: 'html'"></div>
            <div class="hunt__steps">
              @for (step of promo?.HuntHowItWorks; track $index) {
                <div class="hunt__steps_item">
                  <span>{{ $index + 1 }}</span>
                  <div [innerHTML]="step?.title | safe: 'html'"></div>
                  @if ($index === 0) {
                    <img src="/assets/img/promo/halloween/ghost.png" alt="Ghost hunt - wildfortune casino">
                  }
                </div>
              }
            </div>
            @if (user.auth) {
              <button class="btn btn--filled-primary blood" (click)="halloween.onAction(halloween.halloweenAction.HUNT)">
                {{ 't.start-now' | translate }}
              </button>
            } @else {
              <button class="btn btn--filled-primary blood" (click)="halloween.onAction(halloween.halloweenAction.PROMO_AUTH)">
                {{ user.authKeyTranslate | translate }}
              </button>
            }
          } @else {
            @if (halloween?.huntModalData) {
              <p class="hunt__title title-main" [innerHTML]="promo?.HuntTitle | safe: 'html'"></p>
              <img class="hunt__img" src="/assets/img/promo/halloween/hunt-completed.png" alt="Halloween hunt - wildfortune casino">
              <div class="hunt__desc hunt__desc--completed" [innerHTML]="halloween?.huntModalData?.FinalModalTitle | safe: 'html'"></div>
              <div class="hunt__prize" [innerHTML]="halloween?.huntModalData?.Prize | safe: 'html'"></div>
              <div class="hunt__bonus">
                <div class="hunt__bonus_code">{{ 't.code' | translate }}</div>
                <div #code class="hunt__bonus_bonus-code" [innerHTML]="halloween?.huntModalData?.BonusCode | safe: 'html'" (click)="halloween.onAction(halloween.halloweenAction.GO_TO_DEPOSIT,halloween?.huntModalData?.BonusCode)"></div>
                <div class="hunt__bonus_copy" (click)="copy.copy(code, halloween?.huntModalData?.BonusCode)">{{ 't.copy' | translate }}</div>
              </div>
              <div class="hunt__condition" [innerHTML]="halloween?.huntModalData?.FinalModalCondition | safe: 'html'"></div>

              <button class="btn btn--filled-primary blood" (click)="halloween.onAction(halloween.halloweenAction.GO_TO_DEPOSIT,halloween?.huntModalData?.BonusCode)">
                {{ 't.claim-now' | translate }}
              </button>
            }
          }
        </div>
      </div>
    }

    <ng-container>
      @if (halloween.tournaments$ | async; as tournamentsList) {
        <div class="container container-tournaments">
          @if (tournamentsList && tournamentsList.length) {
            <div class="tournaments">
              <p class="title-main">{{ 't.tournaments' | translate }}</p>
              <div class="slider">
                <app-slider #tournamentsSlider [config]="{spacing: 20, autoplay: false, breakpoints: halloween.tournamentsBreakpoints}" [slideList]="tournamentsList">
                  @for (item of tournamentsList; track item) {
                    <div class="slide">
                      <div class="slide__item">
                        <app-tournament-preview
                    [tournament]="{
                          title: item.Title + '<br>' + (item.TitleMiddle ? item.TitleMiddle : ''),
                          description: item.Description ? item.Description : '',
                          image: item.Image,
                          imageMob: item.ImageMob,
                          color: item.ColorBgStart,
                          colorInfo: item.ColorBgEnd,
                          link: item.slug,
                          time: item.active ? item.endAt : item.upcoming ? item.startAt : null,
                          timeLabel: item.closed ? ('t.finished' | translate) : '',
                          prizePool: item.PrizePool,
                          active: item.active,
                          upcoming: item.upcoming,
                          closed: item.closed,
                          tournamentGroup: item.TournamentGroup,
                          vip: item.Vip
                        }"
                        ></app-tournament-preview>
                      </div>
                    </div>
                  }
                </app-slider>
                <div class="pagination-container">
                  @if (tournamentsSlider) {
                    <ng-container *ngTemplateOutlet="tournamentsSlider?.paginationElement"></ng-container>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }
      <div class="container">
        <div class="terms">
          @if (promo?.GeneralTerms) {
            <div class="terms">
              <div class="accordion">
                <div class="accordion__header" Accordion>
                  <div>{{'t.terms-and-cond' | translate}}</div>
                  <i class="accordion__icon icon-pointer-down"></i>
                </div>
                <div class="accordion__content">
                  <div [innerHTML]="promo?.GeneralTerms | safe: 'html'"></div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </ng-container>
  </div>
} @else {
  <div class="preloading"></div>
}

<ng-template #preloading>
  <div class="preloading"></div>
</ng-template>

<app-bottom-navigation></app-bottom-navigation>
<app-footer></app-footer>
