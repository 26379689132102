import { DeviceDetectorService } from 'ngx-unificator/services';
import { environment } from '../../../environments/environment';
import { SsApiService } from './api/ss-api.service';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, interval, of } from 'rxjs';
import { CurrencyConverterService } from './currency-converter.service';
import { UserService } from './user/user.service';
import { ApiProvider } from './api/helpers/api-provider';
import { CommonDataService } from './common-data.service';

@Injectable({
  providedIn: 'root',
})
export class LowPlayerBalanceService {

  /**
   * For API communication
   */
  private _apiMlh: ApiProvider = new ApiProvider(
    {
      defaultOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile',
          'UID': `${this._user.info.id}`,
          'UC': this._user.info.country,
        }),
        withCredentials: true
      },
      host: environment.mlh_host,
      errorHandler: (error: HttpErrorResponse) => {

      }
    }
  );

  private _defaultInterval = 60 * 1000;
  private _interval$: BehaviorSubject<number> = new BehaviorSubject(this._defaultInterval);

  constructor(
    private _api: SsApiService,
    private _user: UserService,
    private _currency: CurrencyConverterService,
    private _device: DeviceDetectorService,
    private _common: CommonDataService
  ) {

  }

  /**
   * Check user balance with dynamic interval and send data
   */
  public checkUserBalance$() {
    return this.getLastDeposit$().pipe(
      tap((lastDeposit: number) => this._calculateInterval(lastDeposit)),
      switchMap(() =>  this._interval$.pipe(switchMap(value => interval(value))).pipe(
        switchMap(() => this.getLastDeposit$()),
        tap((lastDeposit: number) => this._calculateInterval(lastDeposit))
      )),
      tap((lastDeposit: number) => {
        if (lastDeposit) {
          this._checkLowBalance(lastDeposit);
        }
      })
    );
  }

  /**
   * Return last deposit from user transaction for current currency
   */
  public getLastDeposit$() {
    return forkJoin([this._user.getUserAccounts(), this._api.playerPayments()]).pipe(
      map(([acc, payments]) => {
        return payments?.filter(t => t.action === 'deposit' && (t.success || t.state === 'accepted') && t.currency === this._user.info.currency);
      }),
      map((e: any[]) => {
        return e.length ? this._common.subunitsToUnits(e[0].amount_cents, this._user.info.currency) : null;
      }),
    );
  }

  /**
   * Calculate interval for current balance
   * @param lastDeposit
   */
  private _calculateInterval(lastDeposit: number) {
    if (lastDeposit) {
      if (this._user.currentCurrency.amount < lastDeposit) {
        this._interval$.next(20 * 1000 + (40 * 1000 * (this._user.currentCurrency.amount / lastDeposit)));
      } else {
        this._interval$.next(this._defaultInterval);
      }
    }
  }

  /**
   * Check low balance realetive to 10 EUR
   * @param lastDeposit
   */
  private _checkLowBalance(lastDeposit: number) {
    const tenEUR = this._currency.convertEurToAnotherCurrency(10, this._user.info.currency);
    if (this._user.currentCurrency.amount < (lastDeposit / 10) ||
    (this._user.currentCurrency.amount < tenEUR && this._user.currentCurrency.amount < (lastDeposit / 2))) {
      this.sendLowBalance();
    }
  }

  /**
   * Send low balance
   */
  public sendLowBalance() {
    this._apiMlh.get('/balance/low').pipe(
      catchError(() => of(null))
    ).subscribe();
  }
}
