import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { ReplaySubject } from 'rxjs';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import {WindowService} from './window.service';
import {BankingType} from '../shared/types/banking-type';

@Injectable({
  providedIn: 'root'
})
export class BankingService {

  /**
   * Payment methods list from CMS
   */
  private _cmsPaymentMethods: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private _api: CmsApiService,
    private _mapper: CmsContentMapperService,
    private _env: EnvironmentService,
    private _window: WindowService
  ) {
  }

  /**
   * Access to _cmsPaymentMethods
   */
  get cmsPaymentMethods(): ReplaySubject<any> {
    return this._cmsPaymentMethods;
  }

  /**
   * Get payment methods from CMS and save to local variable
   */
  public updateCmsPaymentMethods(type: BankingType, sort_auth_player: number) {
    return this._api.paymentList({ type, sort_auth_player }).pipe(
      map(response => this._mapper.mapCmsData(response.data, {
        slug: 'slug'
      })),
      map(methods => methods.map(method => ({
        ...method,
        trustlyWithTuv: method.slug && method.slug.toLowerCase() === 'trustly' && this._env.isUserFrom('de')
      })).filter(method => !method?.slug?.includes('applepay') || (method?.slug?.includes('applepay') && this._window.isIphoneDevices))),
      map(list => list.map(item => {
        return {
          ...item,
          img: this._resolveImg(item)
        };
      })),
    );
  }

  private _resolveImg(item) {
    switch (true) {
      case item.slug === 'trustly':
        return '/assets/svg/payment-methods/color/trustly-new.svg';
      case item.trustlyWithTuv:
        return '/assets/svg/payment-methods/color/trustly-tuv.svg';
      case item.slug.includes('interac'):
        return 'https://cdn2.softswiss.net/wildfortune/logos/payments/color/interac_color.svg';
      default:
        return 'https://cdn.softswiss.net/logos/payments/color/' + item.slug + '.svg';
    }
  }
}
