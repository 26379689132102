import { Component, OnInit } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {UserService} from '../../../../services/user/user.service';
import { LoginFormComponent } from '../../../../shared/layout/login-form/login-form.component';

@Component({
    selector: 'app-sign-in-modal',
    templateUrl: './sign-in-modal.component.html',
    styleUrls: ['./sign-in-modal.component.scss'],
    standalone: true,
    imports: [LoginFormComponent]
})
export class SignInModalComponent implements OnInit, ModalContent {

  public modal: ModalRef;

  constructor(
    private _user: UserService
  ) { }

  ngOnInit() {
    this._user.auth$.pipe(
      filter(auth => !!auth),
      tap(() => this.modal.close())
    ).subscribe();
  }

}
