import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../../../services/environment.service';
import { TranslatePipe } from '../../../translation/translate.pipe';
import { RouterLinkDirective } from '../../../directives/router-link.directive';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'app-header-not-authorized',
    templateUrl: './header-not-authorized.component.html',
    styleUrls: ['./header-not-authorized.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkDirective, TranslatePipe]
})
export class HeaderNotAuthorizedComponent implements OnInit {

  constructor(
    public env: EnvironmentService,
  ) { }

  ngOnInit() {
  }
}
