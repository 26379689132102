import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  state('void', style({
    opacity: 0
  })),
  transition('void <=> *', animate('0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946)'))
]);

export const showHide = trigger('showHide', [
  state('void', style({
    opacity: 0,
    transform: 'scale(0.6)'
  })),
  transition('void <=> *', animate('0.2s cubic-bezier(0.465, 0.183, 0.153, 0.946)'))
]);
