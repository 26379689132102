import { GameNotAvailableComponent } from './game-not-available/game-not-available.component';
import { GameRequiresFlashComponent } from './game-requires-flash/game-requires-flash.component';
import { SignUpModalComponent } from './sign-up-modal/sign-up-modal.component';
import { TermsAcceptanceFormComponent } from './terms-acceptance-form/terms-acceptance-form.component';
import { SignInModalComponent } from './sign-in-modal/sign-in-modal.component';
import { AuthFieldsMissedUpdateComponent } from './auth-fields-missed-update/auth-fields-missed-update.component';
import { AuthFieldsMissedFormComponent } from './auth-fields-missed-form/auth-fields-missed-form.component';
import { BonusCancelConfirmationComponent } from './bonus-cancel-confirmation/bonus-cancel-confirmation.component';

export const ModalRegularListImports = [
  TermsAcceptanceFormComponent,
  GameNotAvailableComponent,
  GameRequiresFlashComponent,
  AuthFieldsMissedUpdateComponent,
  AuthFieldsMissedFormComponent,
  SignUpModalComponent,
  SignInModalComponent,
  BonusCancelConfirmationComponent,
];

export const ModalRegular = {
  SIGN_UP: SignUpModalComponent,
  SIGN_IN: SignInModalComponent,
  TERMS_ACCEPT: TermsAcceptanceFormComponent,
  AUTH_FIELD_MISSED_UPDATE: AuthFieldsMissedUpdateComponent,
  AUTH_FIELD_MISSED: AuthFieldsMissedFormComponent,
  GAME_NOT_AVAILABLE: GameNotAvailableComponent,
  GAME_REQUIRES_FLASH: GameRequiresFlashComponent,
  BONUS_CANCEL_CONFIRM: BonusCancelConfirmationComponent,
};

export type ModalRegularType = keyof typeof ModalRegular;
