import {
  AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2,
} from '@angular/core';
import { PlatformService } from '../../services/platform.service';
import { fromEvent } from 'rxjs';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { tap, throttleTime } from 'rxjs/operators';

@UntilDestroy()
@Directive({
    selector: '[fontByBlockSize]',
    standalone: true
})
export class FontByBlockSizeDirective implements AfterViewInit, OnDestroy {

  constructor(
    private _platform: PlatformService,
    private _el: ElementRef,
    private _renderer: Renderer2,
  ) {
  }

  ngAfterViewInit() {
    if (this._el && this._el.nativeElement) this._renderer.setStyle(this._el.nativeElement, 'opacity', '0');
    setTimeout(() => {
      this.setFontSize();
    }, 200);

    fromEvent(window, 'resize').pipe(
      untilDestroyed(this),
      throttleTime(500),
      tap(() => this.setFontSize())
    ).subscribe();

  }

  ngOnDestroy() {
  }

  private setFontSize() {
    if (this._platform.isBrowser && this._el) {
      const styles = window.getComputedStyle(this._el.nativeElement);
      const fontsize = parseInt(styles.fontSize && styles.fontSize);
      const child = this._el.nativeElement.children[0];

      const blockHeight = this._el.nativeElement.getBoundingClientRect().height;
      const blockWidth = this._el.nativeElement.getBoundingClientRect().width;
      const childHeight = child.getBoundingClientRect().height;
      const childWidth = child.scrollWidth;

      if ((childHeight === 0 || blockHeight === 0) || childHeight <= blockHeight) {
        setTimeout(() => this._renderer.setStyle(this._el.nativeElement, 'opacity', '1'), 200);
        return;
      }

      if (childHeight <= blockHeight && childWidth <= blockWidth) {
        setTimeout(() => this._renderer.setStyle(this._el.nativeElement, 'opacity', '1'), 200);
        return;
      }

      if (childHeight >= blockHeight || childWidth >= blockWidth) {
        this._renderer.setStyle(this._el.nativeElement, 'fontSize', `${fontsize - 1}px`);
        this.setFontSize();
      }
    }
  }
}
