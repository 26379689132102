import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import { SsPaymentsV2Service } from '../../vendor/ss-payments-v2/ss-payment.service';
import { Payment, PaymentsMethod } from '../../vendor/ss-payments-v2/ss-payment-types';
import {StaticContentService} from '../static-content.service';
import {PlatformService} from '../platform.service';
import {CookieService} from 'ngx-unificator/services';

const IS_USER_HAS_DEPOSIT_COOKIE = '--is-user-has-deposit-cookie';

@Injectable({
  providedIn: 'root'
})
export class UserPaymentSsV2Service {

  /**
   * Payment methods list source
   */
  public invalidCards: string[] = [];

  /**
   * Protected access to payment method list source
   */
  public paymentMethodsList$: ReplaySubject<PaymentsMethod[]> = new ReplaySubject(1);

  public currentPaymentAction: Payment.Action = null;

  /**
   * Limit payment accounts list
   * @private
   */
  private _paymentLimitAccountsList: {[key: string]: string[]};

  /**
   * Checked limit account field
   * @private
   */
  private _checkedLimitField: string;

  /**
   * If true - hide player fields in payment form (It is used when there was an error during the deposit or cashout, but the player fields was saved)
   * @private
   */
  private _hidePlayerFieldsInPaymentForm: boolean;

  /**
   * Toggle for display/hide second deposit step
   */
  public isSecondDepositStep = false;

  /**
   * True if player fields in payment form was saved
   */
  public isPlayerFieldsWasSaved = false;

  constructor(
    private _ssPayments: SsPaymentsV2Service,
    private _static: StaticContentService,
    private _platform: PlatformService,
    private _cookie: CookieService
  ) {

  }


  get hidePlayerFieldsInPaymentForm() { return this._hidePlayerFieldsInPaymentForm;}
  set hidePlayerFieldsInPaymentForm(value) { this._hidePlayerFieldsInPaymentForm = value}

  get isUserHasSuccessDep() {
    if (this._platform.isBrowser) {
      return Boolean(Number(this._cookie.get(IS_USER_HAS_DEPOSIT_COOKIE)));
    }
  }

  set isUserHasSuccessDep(value) {
    if (this._platform.isBrowser) {
      this._cookie.set(IS_USER_HAS_DEPOSIT_COOKIE, '1', 999, '/', (window.location.hostname as any));
    }
  }

  /**
   * Update current payment methods list
   * @param currency
   */
  public updatePaymentMethodsList(currency: string): void {
    if (!currency) {
      this.paymentMethodsList$.next(null);
      return;
    }
    this._ssPayments.fetchMethods(currency, this.currentPaymentAction).pipe(
      tap(list => this.paymentMethodsList$.next(list))
    ).subscribe();
  }

  /**
   * Get limit accounts for payment
   *
   * @param payment
   */
  public getLimitAccountsForPayment(payment: PaymentsMethod): number {
    if (this._paymentLimitAccountsList && this._checkedLimitField) {
      const limit = Object.entries(this._paymentLimitAccountsList).find(l => l[1].some(brands => brands.includes(payment[this._checkedLimitField])));
      return limit ? +limit[0] : null;
    } else {
      return null;
    }
  }

  /**
   * Get limit payment accounts list
   * @private
   */
  private _getLimitAccountsList() {
    this._static.item({slug: 'payment-account-list'}).pipe(
      filter(response => response && response[0]),
      map(response => response[0]),
      tap(response => {
        this._checkedLimitField = response.checkedField;
        let limitList: {[key: string]: string[]};

        if (response.limitList && response.limitList.limitList) {
          limitList = {};
          Object.entries(response.limitList.limitList).forEach((limitArray: [string, string]) => {
            if (!limitList[limitArray[1]]) limitList[limitArray[1]] = [];
            limitList[limitArray[1]].push(limitArray[0]);
          });

          this._paymentLimitAccountsList = limitList;
        }
      })
    ).subscribe();
  }

}
