import { Inject, Injectable } from '@angular/core';
import { WindowService } from './window.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BodyService {
  /**
   * Private variable to check for IOS
   */
  private _isIOSRun: boolean;

  constructor(
    private _window: WindowService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._isIOSRun = this._isIOS();
  }

  /**
   * Getter return true if application runs on IOS
   */
  get isIOS(): boolean {
    return this._isIOSRun;
  }

  /**
   * Return true if application runs on IOS
   *
   * @private
   */
  private _isIOS(): boolean {
    const iosRegex = /ipad|iphone|ipod/i;
    return iosRegex.test(this._window.nativeWindow.navigator.userAgent) || (!!this._window.nativeWindow.navigator.platform && iosRegex.test(this._window.nativeWindow.navigator.platform));
  }
}
