import { SsWebSocketsPrivateChanel, SsWebSocketsPublicChanel } from './ss-web-sockets.types';
import { SsWebSocket } from './ss-web-socket';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';


export class SsSocketChanel {

  /**
   * Messages from chanel
   */
  public message = this._connection.onMessage.pipe(
    map(message => {
      message.data = Array.isArray(message.data) ? message.data.map((item, index) => ({
        ...item,
        uid: `${ message.uid }${ index }`
      })) : {...message.data, uid: message.uid};
      return message;
    }),
    filter(message => message.channel.includes(this._chanel)),
    map(message => ({...message, data: {...message.data}})),
    map(message => message.data),
    switchMap(data => data[0] && typeof data[0] === 'object' ? from(Object.values(data)) : of(data)),
  );

  constructor(
    private _chanel: SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel,
    private _connection: SsWebSocket
  ) {
    _connection.connected$.pipe(
      first(),
      tap(() => {
        _connection.subscribe(_chanel);
      })
    ).subscribe();
  }
}
