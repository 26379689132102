import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { Router, RouterLink } from '@angular/router';
import { GroupsService } from '../../../services/groups.service';
import { tap } from 'rxjs/operators';
import { catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastMessageService } from '../../../modules/toast-message/toast-message.service';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { EnvironmentService } from '../../../services/environment.service';
import { SeoService } from '../../../services/seo.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { TournamentTimerComponent } from '../../components/tournament-time/tournament-time.component';
import { NgClass } from '@angular/common';


export interface TournamentPreviewInfo {
  title: string;
  description: string;
  image: string;
  imageMob: string;
  color: string;
  colorInfo: string;
  link: string;
  time: Date;
  timeLabel: string;
  prizePool: string;
  active: boolean;
  upcoming: boolean;
  closed: boolean;
  tournamentGroup: string | number;
  vip: boolean;
  alwaysRedirectToTournament?: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-tournament-preview',
    templateUrl: './tournament-preview.component.html',
    styleUrls: ['./tournament-preview.component.scss'],
    standalone: true,
    imports: [NgClass, TournamentTimerComponent, RouterLink, RouterLinkDirective, SafePipe, TranslatePipe]
})
export class TournamentPreviewComponent implements OnInit, OnDestroy {

  /**
   * Tournament info
   */
  @Input() tournament: TournamentPreviewInfo;

  /**
   * Is pre-launch group for user
   */
  public isPreLaunchGroup: boolean;

  constructor(
    private _router: Router,
    private _groups: GroupsService,
    private _toastMessage: ToastMessageService,
    public user: UserService,
    public env: EnvironmentService,
    public seo: SeoService
  ) { }

  ngOnInit() {
    this.user.auth$.pipe(
      untilDestroyed(this),
      filter(auth => !!auth && Boolean(this.tournament)),
      tap(() => this.isPreLaunchGroup = this._groups.isExistGroup(this.tournament.tournamentGroup))
    ).subscribe();
  }

  ngOnDestroy() {
  }

  /**
   * Upcoming click action
   *
   */
  public upcomingClickAction() {
    if (this.user.auth && this.tournament) {
      this._groups.addToGroup(this.tournament.tournamentGroup)
        .pipe(
          catchError(error => {
            this._toastMessage.error('t.something-went-wrong');
            return of(error);
          }),
          tap(() => {
            this.isPreLaunchGroup = true;
            this._toastMessage.success('t.tournament-email');
          })
        )
        .subscribe();
    } else {
      this._router.navigateByUrl('/register').then();
    }
  }
}
