import { filter, first, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { SsWebSocketsConfig, SsWebSocketsPrivateChanel, SsWebSocketsPublicChanel } from './ss-web-sockets.types';
import { EventEmitter } from '@angular/core';

export class SsWebSocket {

  /**
   * Current connection
   */
  private _connection: WebSocket;

  /**
   * Socket config
   */
  private _config: SsWebSocketsConfig;

  /**
   * Current user identifier
   */
  private _user: string;

  /**
   * Is now has access to private chanels
   */
  private _accessToPrivate: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Socket identifier
   */
  private _uid = 1;

  /**
   * Is connection betwen client and server open
   */
  private _isOpen: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Is successfully connected
   */
  private _isConnected: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Ready to use messages
   */
  private _onMessage: EventEmitter<{ channel: string, data: any, uid: string | number }> = new EventEmitter();

  /**
   * Protected access to onMessage
   */
  public onMessage = this._onMessage.asObservable();

  constructor(config: SsWebSocketsConfig) {
    this._config = config;

    this._connect();
  }

  /**
   * Access to connected state
   */
  get connected$(): Observable<boolean> {
    return this._isConnected;
  }

  /**
   * Send data to socket
   *
   * @param data
   */
  public send(data: any) {
    if (this._connection) {
      try {
        this._connection.send(JSON.stringify({
          ...data,
          uid: (this._uid++).toString(),
        }));
      } catch (e) {
      }
    }
  }

  /**
   * Subscribe to provided chanel
   *
   * @param chanel
   */
  public subscribe(chanel: SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel) {
    if (this._isPublicChanel(chanel)) {
      this.send({
        method: 'subscribe',
        params: {
          channel: chanel
        }
      });
    } else {
      this._accessToPrivate.pipe(
        filter(access => !!access),
        first(),
        tap(() => {
          this.send({
            method: 'subscribe',
            params: {
              channel: chanel + '#' + this._user
            }
          });
        })
      ).subscribe();
    }
  }

  /**
   * Returns true if chanel is public
   *
   * @param chanel
   * @private
   */
  private _isPublicChanel(chanel: SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel): boolean {
    return Object.values(SsWebSocketsPublicChanel).includes(chanel as any);
  }

  /**
   * Error handle
   *
   * @private
   */
  private _error() {
    this._isOpen.next(false);

    setTimeout(() => {
      this._connect();
    }, 5000);
  }

  /**
   * Connect to server
   *
   * @private
   */
  private _connect() {
    this._config.user.pipe(
      tap(user => {
        this._user = user;
        this._accessToPrivate.next(!!user);
      }),
      filter(() => !this._connection),
      tap(() => {
        this._connection = new WebSocket(this._config.url + '/connection/websocket');

        this._connection.onopen = this._open.bind(this);
        this._connection.onmessage = this._message.bind(this);
        this._connection.onerror = this._error.bind(this);
      })
    ).subscribe();
  }

  /**
   * Open event handle
   *
   * @private
   */
  private _open() {
    this._isOpen.next(true);
    this.send({
      method: 'connect',
      params: {
        user: this._user.toString(),
        token: this._config.token,
        timestamp: this._config.timestamp
      }
    });
  }

  /**
   * On message event
   *
   * @param message
   * @private
   */
  private _message(message) {
    try {
      const data = JSON.parse(message.data);

      switch (data.method) {
        case 'connect':
          this._isConnected.next(true);
          break;
        case 'message':
          if (data.body && data.body.data) {
            this._onMessage.next({
              channel: data.body.channel,
              data: data.body.data,
              uid: data.body.uid
            });
          }
          break;
      }
    } catch (e) {
    }
  }

  /**
   * Close websocket
   */
  public close() {
    this._connection.close();
  }
}
