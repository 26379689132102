import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { catchError, filter, map, tap, first } from 'rxjs/operators';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import {TranslationService} from '../shared/translation/translation.service';
import { StaticContentService } from './static-content.service';
import { EnvironmentService } from './environment.service';

/**
 * Crypto accounts
 */
export const CRYPTO_ACCOUNTS = ['BTC', 'LTC', 'BCH', 'ETH', 'USDT', 'DOG', 'XRP'];

/**
 * Temporary not needed currencies
 */
export const NOT_NEEDED_CURRENCIES = ['THD', 'RUB'];

export const CountryList$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
export const CurrencyList$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
export const GenderList$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


/**
 * Check account is crypto
 *
 * @param currency
 * @private
 */
export function isCryptoAcc(currency: string): boolean {
  return CRYPTO_ACCOUNTS.includes(currency);
}

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  /**
   * List of available countries
   */
  private _countryList: Array<any> = [];

  /**
   * List of available currencies
   */
  private _currencyList: Array<any> = [];

  /**
   * Emits when all data loaded
   */
  private _dataLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * List of available crypto currencies
   */
  private _cryptoCurrencyList: any[] = [];

  constructor(
    private _api: CmsApiService,
    private _translate: TranslationService,
    private _static: StaticContentService,
    private _env: EnvironmentService,
  ) {
    this._getData();
  }

  /**
   * Access to _dataLoaded$ from outside
   */
  get loaded$(): ReplaySubject<boolean> {
    return this._dataLoaded$;
  }

  /**
   * Access to currency list from outside
   */
  get currencyList(): Array<any> {
    return this._currencyList;
  }

  /**
   * Access to country list from outside
   */
  get countryList(): Array<any> {
    return  this._countryList;
  }


  /**
   * Access to crypto currency list from outside
   */
  get cryptoCurrencyList(): any[] {
    return this._cryptoCurrencyList;
  }

  /**
   * Returns symbol of provided currency code
   *
   * @param code
   */
  currencySymbol(code: string): string {
    return (this._currencyList.find(currency => currency.code === code) || {}).symbol || '';
  }

  /**
   * Returns name of country by country short
   *
   * @param short
   */
  countryName(short: string): string {
    return (this._countryList.find(country => country.short === short.toLowerCase()) || {}).name || '';
  }

  /**
   * Convert amount subunits to units (cents to euros)
   *
   * @param amount
   * @param currencyCode
   */
  subunitsToUnits(amount: number, currencyCode: string): number {
    return amount / ((this._currencyList.find(currency => currency.code === currencyCode) || {}).subunits_to_unit || 100);
  }

  /**
   * Convert amount units to subunits (euros to cents)
   *
   * @param amount
   * @param currencyCode
   */
  unitsToSubunits(amount: number, currencyCode: string): number {
    return amount * ((this._currencyList.find(currency => currency.code === currencyCode) || {}).subunits_to_unit || 100);
  }

  /**
   * Get data from backend
   *
   * @private
   */
  private _getData() {
    GenderList$.next([
      ['m', this._translate.translate('t.male')],
      ['f', this._translate.translate('t.female')]
    ]);

    forkJoin([
      this._api.countryList().pipe(
        catchError(error => of({data: []})),
        tap(response => this._countryList = response.data || []),
        tap(response => CountryList$.next(this._countryList))
      ),
      this._api.currencyListSS().pipe(
        catchError(error => of({data: []})),
        map(currencyList => currencyList.data.filter(currency => !NOT_NEEDED_CURRENCIES.includes(currency.code))),
        tap(response => {
          this._currencyList = response || [];
          this._cryptoCurrencyList = this._currencyList.filter(e => !e.fiat);
        }),
        tap(response => CurrencyList$.next(this._currencyList))
      )
    ]).pipe(
      tap(() => this._dataLoaded$.next(true))
    ).subscribe();
  }
  /**
   * Get Fr stags for enable registration
   */
  public getFrStags(): Observable<any> {
    return this._static.item({slug: 'fr-stags'}).pipe(
      filter(data => Boolean(data)),
      first(),
      tap((data) => {
        this._env.setIsSignupRestricted = !data || data && !data.length;
        this._env.isSignupRestricted$.next(!data || data && !data.length);
      }),
    );
  }

  /**
   * Check if is crypto currency
   */
  isCryptoCurrency(currency: string): boolean {
    return this._cryptoCurrencyList.map(e => e.code).includes(currency);
  }

}
