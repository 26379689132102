import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { PlatformService } from '../../services/platform.service';
import { fromEvent, of } from 'rxjs';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { catchError, filter, first, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
    selector: '[ImgDimension]',
    standalone: true
})
export class ImgDimensionDirective implements AfterViewInit, OnDestroy {

  @Input('height') height = 25;

  constructor(
    private _el: ElementRef,
    private _platform: PlatformService
  ) {
  }

  ngAfterViewInit(): void {
    fromEvent(this._el.nativeElement, 'load').pipe(
      untilDestroyed(this),
      first(),
      catchError(error => of(error)),
      filter(() => this._platform.isBrowser),
      tap(el => {
        this._el.nativeElement.height = this.height;
        this._el.nativeElement.width = this._resolveImgWidth(this._el.nativeElement.naturalHeight, this._el.nativeElement.naturalWidth);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
  }

  private _resolveImgWidth(naturalHeight, naturalWidth) {
    return naturalWidth * this.height / naturalHeight;
  }
}
