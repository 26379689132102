import { Pipe, PipeTransform } from '@angular/core';
import {CommonDataService} from '../../services/common-data.service';
import {EnvironmentService} from '../../services/environment.service';

/**
 * Resolve correct display format currencies
 */
@Pipe({
    name: 'displayCurrency',
    standalone: true
})
export class DisplayCurrencyPipe implements PipeTransform {

  private _afterAmount: string[] = ['PLN', 'NOK'];
  private _beforeAmount: string[] = this._env.isUserFrom('de') ? ['CAD', 'NZD'] : ['EUR', 'CAD', 'NZD'];

  constructor(
    private _env: EnvironmentService,
    private _data: CommonDataService
  ) {
  }

  transform(value: any, currencyCode: string): any {
    const currency: string = currencyCode === 'NZD' ? 'nz$' : currencyCode === 'CAD' ? 'c$' : currencyCode === 'AUD' ? 'au$' : this._data.currencySymbol(currencyCode);
    switch(true) {
      case(this._afterAmount.includes(currencyCode)):
        return `${value} ${currency}`;
      case(this._beforeAmount.includes(currencyCode)):
        return `${currency}${value}`;
      default:
        return `${value} ${currency}`;
    }
  }
}
