@if (timeLive) {
  <div class="timer">
    <div class="timer__label">{{ 't.finished-in' | translate }}:</div>
    <div class="timer__value text-color-secondary">{{timeLive?.hours}}</div>
    <div class="timer__label">{{timeLive?.hoursLabel | translate}} :</div>
    <div class="timer__value text-color-secondary">{{timeLive?.minutes}}</div>
    <div class="timer__label">{{timeLive?.minLabel | translate}} :</div>
    <div class="timer__value text-color-secondary">{{timeLive?.seconds}}</div>
    <div class="timer__label">{{timeLive?.secLabel | translate}}</div>
  </div>
}
