import { FiltersService } from './filters.service';
import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { ReplaySubject } from 'rxjs';
import { UserService } from './user/user.service';
import { map, tap } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { LanguageService } from './language/language.service';

export interface GameProvider {
  id: number;
  alias: string;
  name: string;
  restrictCountry: [];
  inFooter?: boolean;
  inFilter?: boolean;
  biggerThanOther?: boolean;
  logo?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GameProvidersService {

  private _providersForGeos: any = {
    au: ['91', '54', '244', '63', '82', '138', '196', '126', '92', '200', '60', '84'],
  };

  private _defaultProvidersList: string[] = ['125', '65', '244', '62', '123', '54', '196', '104', '66', '60', '126', '82'];

  /**
   * Game provider list observable
   */
  private _list$: ReplaySubject<Array<GameProvider>> = new ReplaySubject<Array<GameProvider>>(1);

  /**
   * Game provider list observable for provider slider
   *
   */
  private _listForSlider$: ReplaySubject<Array<GameProvider>> = new ReplaySubject<Array<GameProvider>>(1);

  /**
   * Game provider list
   */
  private _list: Array<GameProvider> = [];

  private _filterProviderList: GameProvider[] = [];

  private _ourSVGProvidersLogo: string[] = ['quickfire', 'wazdan'];
  private _ourPNGProvidersLogo: string[] = ['beterlive'];

  constructor(
    private _api: CmsApiService,
    private _user: UserService,
    private _env: EnvironmentService,
    private _filter: FiltersService,
    private _lang: LanguageService
  ) {
    this._lang.langChange$.subscribe(() => {
      this.updateList();
    });
  }

  /**
   * Access to provider list observable from outside
   */
  get list$(): ReplaySubject<Array<GameProvider>> {
    return this._list$;
  }

  /**
   * Access to provider list for slider observable from outside
   */
  get listForSlider$(): ReplaySubject<Array<GameProvider>> {
    return this._listForSlider$;
  }

  /**
   * Access to provider list from outside
   */
  get list(): Array<GameProvider> {
    return this._list;
  }

  /**
   * Access to filter provider list from outside
   */
  public get filterProviderList() {
    return this._filterProviderList;
  }

  /**
   * Returns provider by id
   *
   * @param id
   */
  getById(id): GameProvider {
    return this._list.find(provider => provider.id === id.toString());
  }

  /**
   * Returns provider by name
   *
   * @param alias
   */
  getByAlias(alias: string): GameProvider {
    alias = alias.toLowerCase().replace('_', '-');

    return this._list.find(provider => provider.alias.toLowerCase() === alias);
  }

  /**
   * Get provider list from CMS and save to local variables
   */
  updateList() {
    let params = {};

    if (this._user.auth) {
      params = {
        country: this._user.info.country
      };
    }

    this._api.gameProviderList(params).pipe(
      map(response => {
        return (response.data || []).map(provider => ({
          ...provider,
          id: provider.id.toString(),
          alias: provider.alias.toLowerCase(),
          logo: this._resolveProviderLogo(provider?.alias),
        }));
      }),
      tap(list => {
        this._list = list;
        this._filterProviderList = list.filter(e => e.inFilter)
          .sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0);
        this._list$.next(this._list);

        const geo = this._env.getUserGeo();

        if (geo && geo.short in this._providersForGeos && this._providersForGeos[geo.short].length > 0) {
          this._listForSlider$.next(this._filter.sortByArrayOrder(this._providersForGeos[geo.short], 'id', list));
        } else {
          this._listForSlider$.next(this._filter.sortByArrayOrder(this._defaultProvidersList, 'id', list));
        }
      })
    ).subscribe();
  }

  /**
   * Resolve provider logo
   * @param alias
   * @private
   */
  private _resolveProviderLogo(alias: string): string {
    return this._ourSVGProvidersLogo.includes(alias) ? `/assets/svg/provider-logos/${alias}.svg`:
    this._ourPNGProvidersLogo.includes(alias) ? `/assets/img/provider-logos/${alias}.png` :
    `https://cdn.softswiss.net//logos/providers/white/${alias}.svg`;
  }
}
